@keyframes animate-in-product-content {
	0% {
		opacity: 0;
		transform: translateX(-3rem) translateZ(0);
	}

	100% {
		opacity: 1;
		transform: translateZ(0);
	}
}

.product-intro {
	background-color: white;
	padding-top: 3.1875rem;
	padding-bottom: 1.5625rem;
}

@media (min-width: 768px) {
	.product-intro {
		padding-top: 8rem;
		padding-bottom: 4rem;
	}
}

@media (max-width: 1023px) {
	.is-scrolling-up .product-intro {
		padding-top: 6.25rem;
	}
}

.sg-section .product-intro {
	padding-top: 0;
}

.product-intro__wrapper {
	overflow: hidden;
	position: relative;
}

@media (min-width: 768px) {
	.product-intro__wrapper {
		display: flex;
	}
}

.product-intro__visuals {
	padding: 2rem 0;
	width: 100vw;
}

@media (min-width: 768px) {
	.product-intro__visuals {
		padding: 0;
		position: absolute;
		top: 0;
		left: 0;
		transform: translateX(-50vw);
		z-index: 1;
	}
}

.product-intro__visuals__letter-grid {
	width: 80%;
}

@media (min-width: 768px) {
	.product-intro__visuals__letter-grid {
		position: absolute;
		top: 0;
		left: 12.5%;
		width: 75%;
		z-index: 1;
	}
}

@media (min-width: 1680px) {
	.product-intro__visuals__letter-grid {
		left: calc((100% - 1260px) / 2);
		width: 1260px;
	}
}

.product-intro__visuals__logo {
	animation: animate-in-product-content 576ms var(--ease-in-out-quad) forwards;
	opacity: 0;
	position: absolute;
	top: calc(7rem - 76px);
	right: 10%;
	transform: translateX(-3rem) translateZ(0);
	width: 125px;
	height: 76px;
	z-index: 2;
}

@media (min-width: 768px) {
	.product-intro__visuals__logo {
		top: 11rem;
		left: 14.5%;
		width: 197px;
		height: 101px;
	}
}

@media (min-width: 1024px) {
	.product-intro__visuals__logo {
		top: 14rem;
		left: 14.5%;
	}
}

@media (min-width: 1380px) {
	.product-intro__visuals__logo {
		top: 17rem;
		left: 15.5%;
		width: 230px;
		height: 140px;
	}
}

@media (min-width: 1680px) {
	.product-intro__visuals__logo {
		left: calc((100% - 1260px) / 2 + 3rem);
	}
}

.t-airclad-x .product-intro__visuals__logo {
	top: calc(7rem - 28px);
	width: 150px;
	height: 28px;
}

@media (min-width: 768px) {
	.t-airclad-x .product-intro__visuals__logo {
		width: 197px;
		height: 36px;
	}
}

@media (min-width: 1380px) {
	.t-airclad-x .product-intro__visuals__logo {
		width: 276px;
		height: 51px;
	}
}

.product-intro__visuals__logo .icon {
	display: block;
	fill: black;
	width: 100%;
	height: 100%;
}

.product-intro__info {
	animation: animate-in-product-content 576ms var(--ease-in-out-quad) 576ms forwards;
	opacity: 0;
	position: relative;
	transform: translateX(-3rem) translateZ(0);
}

@media (max-width: 767px) {
	.product-intro__info {
		z-index: 3;
	}
}

@media (min-width: 768px) {
	.product-intro__info {
		display: flex;
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
		min-height: 37.5rem;
	}
}

@media (min-width: 1280px) {
	.product-intro__info {
		box-sizing: border-box;
		padding-left: 3rem;
	}
}

@media (min-width: 1380px) {
	.product-intro__info {
		padding-left: 6.75rem;
		min-height: 40rem;
	}
}

@media (min-width: 1680px) {
	.product-intro__info {
		padding-left: 12.75rem;
	}
}

.product-intro__info__content,
.product-intro__info__intro,
.product-intro__info__media {
	display: block;
	position: relative;
	max-width: 100%;
}

.product-intro__info__intro,
.product-intro__info__media {
	margin-bottom: 2rem;
}

@media (min-width: 768px) {
	.product-intro__info__intro:last-child,
	.product-intro__info__media:last-child {
		margin-bottom: 0;
	}
}

.product-intro__info__content,
.product-intro__info__intro {
	z-index: 3;
}

.product-intro__info__media {
	overflow: hidden;
	pointer-events: none;
	text-align: right;
	transform: scale3d(1.2, 1.2, 1);
	width: 100%;
	z-index: 2;
}

@media (min-width: 1024px) {
	.product-intro__info__media {
		padding-top: 12.5%;
		padding-bottom: 12.5%;
		transform: scale3d(1.4, 1.4, 1);
		width: 120%;
		max-width: none;
	}
}

@media (min-width: 1380px) {
	.product-intro__info__media {
		width: 150%;
	}
}

.product-intro__info__intro {
	font-weight: var(--font-weight-bold);
}

@media (min-width: 768px) {
	.product-intro__info__intro {
		font-size: 1.125rem;
	}
}

@media (min-width: 1024px) {
	.product-intro__info__intro {
		font-size: 1.25rem;
	}
}

.product-intro__info__intro > p {
	font-size: inherit;
}

.product-intro__info__content {
	box-sizing: border-box;
	overflow: hidden;
	padding-bottom: 3rem;
	position: relative;
}

@media (min-width: 1024px) {
	.product-intro__info__content {
		font-size: 1.125rem;
	}
}

.product-intro__info__content > p {
	font-size: inherit;
}

.product-intro__info__content-toggle:not(:checked) ~ .product-intro__info__content {
	max-height: 270px;
}

.product-intro__info__content-toggle:not(:checked) ~ .product-intro__info__content::after {
	background: linear-gradient(to bottom, rgb(255 255 255 / 0) 0%, rgb(255 255 255 / 1) 60%);
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 8rem;
	z-index: 1;
}

.product-intro__info__content-toggle:checked ~ .product-intro__info__content {
	max-height: none;
}

.product-intro__info__content-toggle:checked ~ .product-intro__info__content::after {
	display: none;
}

.product-intro__info__content-label {
	cursor: pointer;
	font-weight: var(--font-weight-bold);
	position: absolute;
	bottom: 1rem;
	left: 0;
	z-index: 2;
}

.t-expro .product-intro__info__content-label {
	color: var(--color-blue);
}

.t-express .product-intro__info__content-label {
	color: var(--color-red);
}

.t-xplay .product-intro__info__content-label {
	color: var(--color-warm-grey);
}

.t-xtrail .product-intro__info__content-label {
	color: var(--color-purple);
}

.product-intro__info__content-label::after {
	content: attr(data-more);
}

.product-intro__info__content-toggle:checked ~ .product-intro__info__content .product-intro__info__content-label::after {
	content: attr(data-less);
}

.product-intro__links {
	color: black;
	font-family: var(--font-title);
}

@media (min-width: 768px) {
	.product-intro__links {
		display: flex;
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		order: -1;
		align-items: flex-end;
	}
}

.t-expro .product-intro__links {
	color: var(--color-blue);
}

.t-express .product-intro__links {
	color: var(--color-red);
}

.t-xplay .product-intro__links {
	color: var(--color-warm-grey);
}

.t-xtrail .product-intro__links {
	color: var(--color-purple);
}

.product-intro__links__list {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
}

.product-intro__links__list__link {
	border-bottom-color: var(--color-transparent-black);
	border-bottom-style: solid;
	border-bottom-width: 1px;
	color: currentColor;
	font-size: 0.75rem;
	font-weight: var(--font-weight-bold);
	letter-spacing: 1px;
	line-height: 1.5833;
	padding-bottom: 2px;
	text-decoration: none;
	text-transform: uppercase;
	transition: border-bottom-color 96ms linear;
}

@media (min-width: 1024px) {
	.product-intro__links__list__link {
		font-size: 0.875rem;
	}
}

.t-expro .product-intro__links__list__link {
	border-bottom-color: var(--color-transparent-blue);
}

.t-express .product-intro__links__list__link {
	border-bottom-color: var(--color-transparent-red);
}

.t-xplay .product-intro__links__list__link {
	border-bottom-color: var(--color-transparent-warm-grey);
}

.t-xtrail .product-intro__links__list__link {
	border-bottom-color: var(--color-transparent-purple);
}

.product-intro__links__list__link:hover {
	border-bottom-color: var(--color-faded-black);
}

.t-expro .product-intro__links__list__link:hover {
	border-bottom-color: var(--color-faded-blue);
}

.t-express .product-intro__links__list__link:hover {
	border-bottom-color: var(--color-faded-red);
}

.t-xplay .product-intro__links__list__link:hover {
	border-bottom-color: var(--color-faded-warm-grey);
}

.t-xtrail .product-intro__links__list__link:hover {
	border-bottom-color: var(--color-faded-purple);
}

.post-teaser {
	color: currentColor;
	display: block;
	overflow: hidden;
	position: relative;
	text-decoration: none;
	width: 100%;
}

@media (min-width: 768px) {
	.post-teaser {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		height: 100%;
	}
}

.post-teaser__bottom,
.post-teaser__top {
	width: 100%;
}

.post-teaser__thumbnail-container {
	display: block;
	overflow: hidden;
	padding-bottom: 66.6666%;
	position: relative;
	width: 100%;
}

.post-teaser__thumbnail-container::after {
	background-color: var(--color-grey-light);
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.post-teaser__thumbnail-container::before {
	background-color: var(--color-faded-black);
	content: "";
	opacity: 0;
	position: absolute;
	transition: opacity 96ms linear;
	width: 100%;
	height: 100%;
	z-index: 3;
}

.post-teaser__thumbnail-container .icon {
	content: "";
	display: block;
	fill: white;
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: opacity 96ms linear;
	width: 6.25rem;
	height: 6.25rem;
	z-index: 4;
}

.post-teaser:focus .post-teaser__thumbnail-container::before,
.post-teaser:hover .post-teaser__thumbnail-container::before,
.post-teaser:focus .post-teaser__thumbnail-container .icon,
.post-teaser:hover .post-teaser__thumbnail-container .icon {
	opacity: 1;
}

.post-teaser__thumbnail-container__asset {
	display: block;
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
}

.post-teaser__date {
	color: var(--color-grey);
	font-size: 0.8125rem;
	line-height: 1.92;
	margin-bottom: 0;
}

@media (min-width: 1280px) {
	.post-teaser__date {
		margin-top: 0.3125rem;
		margin-bottom: 0.3125rem;
	}
}

.post-teaser__title {
	font-family: var(--font-title);
	font-size: 1.125rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.33;
}

@media (min-width: 1280px) {
	.post-teaser__title {
		font-size: 1.25rem;
		line-height: 1.2;
	}
}

.post-teaser__top:not(:last-child) .post-teaser__title {
	margin-bottom: 0.3125rem;
}

@media (min-width: 1280px) {
	.post-teaser__top:not(:last-child) .post-teaser__title {
		margin-bottom: 0.625rem;
	}
}

.post-teaser:focus .post-teaser__title,
.post-teaser:hover .post-teaser__title {
	text-decoration: underline;
}

.post-teaser__excerpt {
	font-size: 0.9375rem;
	line-height: 1.6;
	margin-bottom: 0;
}

@media (min-width: 1280px) {
	.post-teaser__excerpt {
		font-size: 1rem;
		line-height: 1.44;
	}
}

.single-media {
	display: block;
	position: relative;
	max-width: 100%;
}

.single-media__container {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
	z-index: 1;
}

.single-media__container[data-aspect-ratio="1:1"] {
	padding-bottom: 100%;
}

.single-media__container[data-aspect-ratio="1:2"] {
	padding-bottom: 200%;
}

.single-media__container[data-aspect-ratio="2:1"] {
	padding-bottom: 50%;
}

.single-media__container[data-aspect-ratio="3:4"] {
	padding-bottom: 133.3333%;
}

.single-media__container[data-aspect-ratio="4:3"] {
	padding-bottom: 75%;
}

.single-media__container[data-aspect-ratio="21:9"] {
	padding-bottom: 42.8571%;
}

.single-media__media {
	display: block;
	width: 100%;
	height: auto;
	z-index: 1;
}

.single-media__container .single-media__media {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	min-width: 100%;
	max-width: none;
	height: auto;
	min-height: 100%;
}

@supports (object-fit: cover) {
	.single-media__container .single-media__media {
		object-fit: cover;
		object-position: 50% 50%;
		width: 100%;
		height: 100%;
	}
}

.single-media__caption {
	box-sizing: border-box;
	color: white;
	display: inline-block;
	/* stylelint-disable-next-line declaration-property-value-no-unknown -- broken, must be fixed by a human */
	font-stretch: 0.625rem;
	padding: 1rem;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 2;
}

@media (min-width: 1024px) {
	.single-media__caption {
		font-size: 0.75rem;
	}
}

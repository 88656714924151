/*
 *
 * This element will assume the dimensions of it's parent container
 * to cover it. Without a parent container, it won't have a height.
 *
 * Use it with a specifically styled container, not as a standalone component.
 *
 * e.g.
 *
 * <div class="hero">
 *    <div class="hero__background">
 *        <div class="video">
 *            <div class="video__poster">...</div>
 *            <div class="video__video"> ...</div>
 *        </div>
 *    </div>
 * </div>
 *
 */

.video {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video__media,
.video__poster {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	min-width: 100%;
	max-width: none;
	height: auto;
	min-height: 100%;
}

@supports (object-fit: cover) {
	.video__media,
	.video__poster {
		object-fit: cover;
		object-position: 50% 50%;
		width: 100%;
		height: 100%;
	}
}

.video__poster {
	opacity: 1;
	transition: opacity 96ms linear;
	z-index: 2;
}

.video__poster.is-hidden {
	opacity: 0;
}

.video__media {
	z-index: 1;
}

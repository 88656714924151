.landing-site-header {
	background-color: var(--color-white);
	border-bottom: 1px solid currentColor;
	color: var(--color-black);
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 90;
}

@media (min-width: 768px) {
	.landing-site-header::after {
		content: "";
		margin: 1.25rem 1.5625rem;
	}
}

.landing-site-header__logo,
.landing-site-header__logo .icon,
.landing-site-header::after {
	width: 109px;
	height: 21px;
}

.landing-site-header__logo {
	line-height: 0;
}

@media (max-width: 767px) {
	.landing-site-header__logo {
		border-right: 1px solid currentColor;
		margin-bottom: 0;
		padding: 1rem 1.5625rem; /* using content-box here 👌 */
	}
}

@media (min-width: 768px) {
	.landing-site-header__logo {
		margin: 1.25rem 1.5625rem !important;
	}
}

.landing-site-header__navigation {
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.landing-site-header__navigation-list {
	display: inline-block;
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

.landing-site-header__navigation-list__item {
	display: inline-block;
}

@media (max-width: 767px) {
	.landing-site-header__navigation-list__item:nth-child(n + 2) {
		/* VISUALLY HIDDEN */
		border: none;
		clip: rect(0 0 0 0);
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		white-space: nowrap;
		width: 1px;
		height: 1px;
	}
}

.landing-site-header__navigation-list__link {
	box-sizing: border-box;
	color: currentColor;
	display: block;
	font-size: 0.75rem;
	font-weight: var(--font-weight-normal);
	padding: 1rem;
	text-decoration: none;
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.landing-site-header__navigation-list__link {
		padding: 1.5625rem 0.625rem;
	}
}

.landing-site-header__navigation-list__link:focus,
.landing-site-header__navigation-list__link:hover {
	font-weight: var(--font-weight-bold); /* this is a shitty hoverstate */
}

.form {
	display: block;
	width: 100%;
}

.form__row {
	position: relative;
	width: 100%;
}

.form__row:not(*:last-of-type) {
	margin-bottom: 1.375rem;
}

@media (min-width: 1024px) {
	.form__row:not(*:last-of-type) {
		margin-bottom: 2rem;
	}
}

.form__label {
	color: black;
	display: inline-block;
	font-size: 0.5625rem;
	font-weight: var(--font-weight-bold);
	letter-spacing: 1px;
	line-height: 1;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	text-transform: uppercase;
	bottom: -1.125rem;
	left: 0;
	transition:
		color 96ms linear,
		opacity 96ms linear;
}

@media (min-width: 1024px) {
	.form__label {
		font-size: 0.6875rem;
	}
}

.form__input:focus + .form__label,
.form__input[value]:not([value=""]) + .form__label {
	opacity: 1;
	z-index: 1;
}

.t-expro .form__input:focus + .form__label {
	color: var(--color-blue);
}

.t-express .form__input:focus + .form__label {
	color: var(--color-red);
}

.t-xplay .form__input:focus + .form__label {
	color: var(--color-warm-grey);
}

.t-xtrail .form__input:focus + .form__label {
	color: var(--color-purple);
}

.form__input {
	appearance: none;
	background: none;
	border-top: 0;
	border-right: 0;
	border-bottom-color: black;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-left: 0;
	border-radius: 0;
	box-shadow: none;
	color: black;
	line-height: 3rem;
	outline: 0;
	padding: 0;
	position: relative;
	text-align: left;
	transition: border-bottom-color 96ms linear;
	width: 100%;
	height: 3rem;
	z-index: 2;
}

@media (min-width: 1024px) {
	.form__input {
		line-height: 4.6875rem;
		height: 4.6875rem;
	}
}

.t-expro .form__input:focus {
	border-bottom-color: var(--color-blue);
}

.t-express .form__input:focus {
	border-bottom-color: var(--color-red);
}

.t-xplay .form__input:focus {
	border-bottom-color: var(--color-warm-grey);
}

.t-xtrail .form__input:focus {
	border-bottom-color: var(--color-purple);
}

.form__input::placeholder {
	color: black;
}

.form__submit {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	color: black;
	display: block;
	font-family: var(--font-title);
	font-size: 0.875rem;
	font-weight: var(--font-weight-bold);
	margin-top: 3rem;
	opacity: 1;
	outline: 0;
	padding: 0;
	text-align: left;
	text-transform: uppercase;
	transition: opacity 96ms linear;
	width: 100%;
}

@media (min-width: 1024px) {
	.form__submit {
		font-size: 1.25rem;
		margin-top: 5rem;
	}
}

@media (min-width: 1380px) {
	.form__submit {
		margin-top: 7rem;
	}
}

.form__submit:hover {
	cursor: pointer;
}

.form__submit:focus,
.form__submit:hover {
	opacity: 0.6;
}

.t-expro .form__submit {
	color: var(--color-blue);
}

.t-express .form__submit {
	color: var(--color-red);
}

.t-xplay .form__submit {
	color: var(--color-warm-grey);
}

.t-xtrail .form__submit {
	color: var(--color-purple);
}

.form__error {
	color: var(--color-grey);
	font-size: 0.9375rem;
	padding-top: 0.75rem;
}

.form__error__label {
	font-size: inherit;
	margin-bottom: 0;
}

/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.slideshow {
	position: relative;
}

.slideshow__wrapper {
	overflow: hidden;
	position: relative;
}

.slideshow__track {
	box-sizing: border-box;
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
	padding-left: 0;
	transition: transform 688ms cubic-bezier(0.535, 0, 0.175, 1);
	user-select: none;
	/* stylelint-disable-next-line declaration-property-value-no-unknown -- broken, must be fixed by a human */
	white-space: wrap;
}

@media (min-width: 768px) {
	.slideshow__track {
		padding-left: 12.5%;
	}
}

@media (min-width: 1024px) {
	.slideshow__track {
		transition: transform 720ms cubic-bezier(0.655, 0, 0.145, 1);
	}
}

@media (min-width: 1680px) {
	.slideshow__track {
		padding-left: calc((100% - 1260px) / 2);
	}
}

mr-slideshow.is-resolved .slideshow__track {
	overflow: visible;
}

.is-dragged .slideshow__track {
	transition: none;
}

.slideshow__item {
	box-sizing: border-box;
	flex: 0 0 100%;
	padding-left: 10%;
}

@media (min-width: 768px) {
	.slideshow__item {
		flex: 0 0 80%;
		padding-right: 2rem;
		padding-left: 0;
	}
}

@media (min-width: 768px) {
	.slideshow__item {
		padding-right: 3rem;
	}
}

@media (min-width: 1280px) {
	.slideshow__item {
		flex: 0 0 75%;
		padding-right: 4.25rem;
	}
}

mr-slideshow[use-click="true"] .slideshow__item:hover {
	cursor: pointer;
}

.slideshow__media__container {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.slideshow__media {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	min-width: 100%;
	max-width: none;
	height: auto;
	min-height: 100%;
}

@supports (object-fit: cover) {
	.slideshow__media {
		object-fit: cover;
		object-position: 50% 50%;
		width: 100%;
		height: 100%;
	}
}

.slideshow__caption {
	box-sizing: border-box;
	display: inline-block;
	font-size: 0.875rem;
	line-height: 1.5;
	margin-top: 1.1875rem;
	margin-bottom: 0;
	padding-right: 25%;
}

@media (min-width: 1280px) {
	.slideshow__caption {
		font-size: 1rem;
		margin-top: 1.5625rem;
	}
}

.slideshow__button {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	color: black;
	display: flex;
	outline: 0;
	padding: 0;
	justify-content: center;
	align-items: center;
	position: absolute;
	height: 10vw;

	/* centered to slideshow item height */
	top: calc(((90vw * 0.5625) / 2) - 5vw);
	width: 10vw;
}

@media (min-width: 768px) {
	.slideshow__button {
		display: none;
	}
}

.t-expro .slideshow__button {
	color: var(--color-blue);
}

.t-express .slideshow__button {
	color: var(--color-red);
}

.t-xplay .slideshow__button {
	color: var(--color-warm-grey);
}

.t-xtrail .slideshow__button {
	color: var(--color-purple);
}

.slideshow__button .icon {
	display: block;
	fill: currentColor;
	transform: rotate(90deg);
	transition: transform 320ms var(--ease-in-out);
	width: 1.125rem;
	height: 1.125rem;
}

.slideshow.is-at-end .slideshow__button .icon {
	transform: rotate(-90deg);
}

.point-of-view-gallery {
	position: relative;
}

.point-of-view-gallery__wrapper {
	overflow: hidden;
	position: relative;
}

.point-of-view-gallery__track {
	box-sizing: border-box;
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
	transition: opacity 160ms linear;
}

mr-point-of-view-gallery.is-resolved .point-of-view-gallery__track {
	overflow: visible;
}

.point-of-view-gallery__track.is-hidden {
	opacity: 0;
}

.point-of-view-gallery__item {
	box-sizing: border-box;
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	position: relative;
	text-align: center;
}

.point-of-view-gallery__item__image {
	display: block;
	width: 100%;
	height: auto;
}

.point-of-view-gallery__item__caption {
	box-sizing: border-box;
	color: var(--color-grey);
	font-size: 0.875rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-right: 5%;
	padding-left: 5%;
	text-align: center;
	width: 100%;
	height: 2rem;
}

@media (max-width: 1023px) {
	.point-of-view-gallery__item__caption {
		display: block;
		margin-right: auto;
		margin-left: auto;
		overflow: hidden;
		padding-right: 0;
		padding-left: 0;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 240px;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.point-of-view-gallery__item__caption {
		max-width: 640px;
	}
}

@media (min-width: 1024px) {
	.point-of-view-gallery__item__caption {
		font-size: 1rem;
		padding-right: 25%;
		padding-left: 25%;
		height: 3.5rem;
	}
}

@media (min-width: 1380px) {
	.point-of-view-gallery__item__caption {
		height: 4.875rem;
	}
}

.t-expro .point-of-view-gallery__item__caption {
	color: var(--color-blue);
}

.t-express .point-of-view-gallery__item__caption {
	color: var(--color-red);
}

.t-xplay .point-of-view-gallery__item__caption {
	color: var(--color-warm-grey);
}

.t-xtrail .point-of-view-gallery__item__caption {
	color: var(--color-purple);
}

.point-of-view-gallery__hotspots {
	position: absolute;
	bottom: 4rem;
	left: 0;
	width: 100%;
	height: calc(100% - 4rem);
	z-index: 9;
}

@media (min-width: 1024px) {
	.point-of-view-gallery__hotspots {
		bottom: 5.5rem;
		height: calc(100% - 5.5rem);
	}
}

@media (min-width: 1380px) {
	.point-of-view-gallery__hotspots {
		bottom: 6.875rem;
		height: calc(100% - 6.875rem);
	}
}

.point-of-view-gallery__hotspots__hotspot {
	animation: none;
	appearance: none;
	background: black;
	border: 0;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgb(0 0 0 / 0.3);
	display: block;
	opacity: 0;
	outline: 0;
	padding: 0 !important;
	pointer-events: none;
	position: absolute;
	text-indent: -9999px;
	transform: translate(-50%, -50%) scale(0.5);
	transition:
		opacity 96ms linear,
		transform 320ms var(--ease-in-out);
	width: 1.125rem;
	height: 1.125rem;
}

@media (min-width: 768px) {
	.point-of-view-gallery__hotspots__hotspot {
		width: 0.9375rem;
		height: 0.9375rem;
	}
}

.point-of-view-gallery__hotspots__hotspot:hover {
	cursor: pointer;
}

.t-expro .point-of-view-gallery__hotspots__hotspot {
	background-color: var(--color-blue);
	box-shadow: 0 0 0 0 var(--color-faded-blue);
}

.t-express .point-of-view-gallery__hotspots__hotspot {
	background-color: var(--color-red);
	box-shadow: 0 0 0 0 var(--color-faded-red);
}

.t-xplay .point-of-view-gallery__hotspots__hotspot {
	background-color: var(--color-warm-grey);
	box-shadow: 0 0 0 0 var(--color-faded-warm-grey);
}

.t-xtrail .point-of-view-gallery__hotspots__hotspot {
	background-color: var(--color-purple);
	box-shadow: 0 0 0 0 var(--color-faded-purple);
}

.point-of-view-gallery__hotspots__hotspot.is-showing {
	opacity: 1;
	pointer-events: auto;
	transform: translate(-50%, -50%) scale(1);
}

.point-of-view-gallery__hotspots__hotspot:not(.is-active) {
	animation: PULSE_BLACK 2000ms infinite;
}

.t-expro .point-of-view-gallery__hotspots__hotspot:not(.is-active) {
	animation: PULSE_BLUE 2000ms infinite;
}

.t-express .point-of-view-gallery__hotspots__hotspot:not(.is-active) {
	animation: PULSE_RED 2000ms infinite;
}

.t-xplay .point-of-view-gallery__hotspots__hotspot:not(.is-active) {
	animation: PULSE_WARM_GREY 2000ms infinite;
}

.t-xtrail .point-of-view-gallery__hotspots__hotspot:not(.is-active) {
	animation: PULSE_PURPLE 2000ms infinite;
}

@keyframes PULSE_BLACK {
	0% {
		box-shadow: 0 0 0 0 rgb(0 0 0 / 0.45);
	}

	70% {
		box-shadow: 0 0 0 1rem rgb(0 0 0 / 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgb(0 0 0 / 0);
	}
}

@keyframes PULSE_BLUE {
	0% {
		box-shadow: 0 0 0 0 rgb(97 141 180 / 0.45);
	}

	70% {
		box-shadow: 0 0 0 1rem rgb(97 141 180 / 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgb(97 141 180 / 0);
	}
}

@keyframes PULSE_PURPLE {
	0% {
		box-shadow: 0 0 0 0 rgb(134 100 121 / 0.45);
	}

	70% {
		box-shadow: 0 0 0 1rem rgb(134 100 121 / 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgb(134 100 121 / 0);
	}
}

@keyframes PULSE_RED {
	0% {
		box-shadow: 0 0 0 0 rgb(255 0 79 / 0.45);
	}

	70% {
		box-shadow: 0 0 0 1rem rgb(255 0 79 / 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgb(255 0 79 / 0);
	}
}

@keyframes PULSE_WARM_GREY {
	0% {
		box-shadow: 0 0 0 0 rgb(172 161 153 / 0.45);
	}

	70% {
		box-shadow: 0 0 0 1rem rgb(172 161 153 / 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgb(172 161 153 / 0);
	}
}

.l-error {
	padding-top: 6.25rem;
}

@media (min-width: 768px) {
	.l-error {
		padding-top: 8rem;
		padding-bottom: 4rem;
	}
}

@media (max-width: 1023px) {
	.is-scrolling-up .l-error {
		padding-top: 9.375rem;
	}
}

@media (min-width: 768px) {
	.l-error__wrapper {
		display: flex;
	}
}

.l-error__icon {
	color: black;
}

@media (max-width: 767px) {
	.l-error__icon {
		margin-bottom: 2rem;
	}
}

@media (min-width: 768px) {
	.l-error__icon {
		flex-basis: 40%;
		flex-shrink: 0;
	}
}

@media (min-width: 1024px) {
	.l-error__icon {
		flex-basis: 50%;
	}
}

.l-error__icon .icon {
	display: block;
	fill: currentColor;
	width: 10rem;
	height: 10rem;
}

@media (min-width: 1024px) {
	.l-error__icon .icon {
		width: 15rem;
		height: 15rem;
	}
}

@media (min-width: 1280px) {
	.l-error__icon .icon {
		width: 20rem;
		height: 20rem;
	}
}

@media (min-width: 1680px) {
	.l-error__icon .icon {
		width: 30rem;
		height: 30rem;
	}
}

@media (min-width: 768px) {
	.l-error__content {
		flex-grow: 1;
		padding-top: 3rem;
	}
}

@media (min-width: 1024px) {
	.l-error__content {
		padding-top: 4rem;
	}
}

@media (min-width: 1280px) {
	.l-error__content {
		padding-top: 5rem;
	}
}

@media (min-width: 1680px) {
	.l-error__content {
		padding-top: 6rem;
	}
}

.l-error__content__title {
	box-sizing: border-box;
	color: black;
	display: block;
	font-family: var(--font-title);
	font-size: 1.5625rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.08;
	padding-top: 2.5rem;
	padding-right: 25%;
	padding-bottom: 2.5rem;
	position: relative;
	text-transform: uppercase;
	width: 100%;
}

@media (min-width: 768px) {
	.l-error__content__title {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (min-width: 1024px) {
	.l-error__content__title {
		font-size: 2.5rem;
		padding-top: 5.5rem;
		padding-bottom: 5.5rem;
	}
}

@media (min-width: 1380px) {
	.l-error__content__title {
		font-size: 4rem;
		padding-top: 6.875rem;
		padding-bottom: 6.875rem;
	}
}

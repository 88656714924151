.locked-link {
	color: currentColor;
	display: inline-block;
	position: relative;
}

.locked-link .icon-bracket-left,
.locked-link .icon-bracket-right {
	display: block;
	opacity: 0;
	position: absolute;
	top: 50%;
	transition:
		transform 120ms ease-in-out,
		opacity 96ms linear;
	width: 5px;
	height: 100%;
}

.locked-link.locked-link--roboto .icon-bracket-left,
.locked-link.locked-link--roboto .icon-bracket-right {
	top: 55%;
}

.locked-link .icon-bracket-left {
	left: 0;
	transform: translate(-200%, -50%) translateZ(0);
}

.locked-link .icon-bracket-right {
	right: 0;
	transform: translate(200%, -50%) translateZ(0);
}

.locked-link.locked-link--small .icon-bracket-left,
.locked-link.locked-link--small .icon-bracket-right {
	width: 3px;
}

.locked-link.locked-link--small-medium .icon-bracket-left,
.locked-link.locked-link--small-medium .icon-bracket-right {
	width: 5px;
}

@media (min-width: 768px) {
	.locked-link.locked-link--medium .icon-bracket-left,
	.locked-link.locked-link--medium .icon-bracket-right {
		width: 8px;
	}
}

.locked-link.locked-link--large .icon-bracket-left,
.locked-link.locked-link--large .icon-bracket-right {
	width: 10px;
}

@media (min-width: 768px) {
	.locked-link.locked-link--large .icon-bracket-left,
	.locked-link.locked-link--large .icon-bracket-right {
		width: 30px;
		height: 90px;
	}
}

a:hover .locked-link .icon-bracket-left,
a:hover .locked-link .icon-bracket-right,
label:hover .locked-link .icon-bracket-left,
label:hover .locked-link .icon-bracket-right {
	cursor: pointer;
	opacity: 1;
}

a:hover .locked-link .icon-bracket-left,
label:hover .locked-link .icon-bracket-left {
	transform: translate(-150%, -50%) translateZ(0);
}

a:hover .locked-link .icon-bracket-right,
label:hover .locked-link .icon-bracket-right {
	transform: translate(150%, -50%) translateZ(0);
}

a:hover .locked-link.locked-link--small-medium .icon-bracket-left,
label:hover .locked-link.locked-link--small-medium .icon-bracket-left {
	transform: translate(-165%, -50%) translateZ(0);
}

a:hover .locked-link.locked-link--large .icon-bracket-right,
label:hover .locked-link.locked-link--large .icon-bracket-right {
	transform: translate(165%, -50%) translateZ(0);
}

a:hover .locked-link.locked-link--large .icon-bracket-left,
label:hover .locked-link.locked-link--large .icon-bracket-left {
	transform: translate(-100%, -50%) translateZ(0);
}

a:hover .locked-link.locked-link--large .icon-bracket-right,
label:hover .locked-link.locked-link--large .icon-bracket-right {
	transform: translate(100%, -50%) translateZ(0);
}

.locked-link__arrow {
	border-color: transparent transparent transparent currentColor;
	border-style: solid;
	border-width: 0.45rem 0 0.45rem 0.75rem;
	display: inline-block;
	transform: translate(0, -0.125rem);
	vertical-align: middle;
}

.news-posts-header__format-link .locked-link__arrow {
	transform: translateY(-0.1875rem);
}

.post-grid {
	font-size: 0;
}

@supports (grid-template-columns: repeat(2, 1fr)) {
	.post-grid {
		font-size: inherit;
	}

	@media (min-width: 768px) {
		.post-grid {
			display: grid;
			grid-row-gap: 1.625rem;
			grid-column-gap: 1.875rem;
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media (min-width: 1024px) {
		.post-grid {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	@media (min-width: 1280px) {
		.post-grid {
			grid-row-gap: 3rem;
		}
	}

	@media (min-width: 1680px) {
		.post-grid {
			grid-row-gap: 4rem;
		}
	}
}

@supports (grid-template-columns: repeat(2, 1fr)) {
	@media (min-width: 768px) {
		.post-grid.post-grid--only-3 {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

.post-grid + .pagination {
	margin-top: 2rem;
}

@media (min-width: 1024px) {
	.post-grid + .pagination {
		margin-top: 3rem;
	}
}

.post-grid__item {
	margin-bottom: 1.875rem;
}

@media (min-width: 768px) {
	.post-grid__item {
		display: inline-block;
		margin-right: 1.875rem;
		width: calc(50% - 1.875rem);
	}
}

@media (min-width: 1024px) {
	.post-grid__item {
		width: calc(33.3333% - 1.875rem);
	}
}

@supports (grid-template-columns: repeat(2, 1fr)) {
	@media (min-width: 768px) {
		.post-grid__item {
			margin-right: 0;
			margin-bottom: 0;
			width: auto;
		}
	}
}

@media (min-width: 768px) {
	.post-grid--only-3 .post-grid__item {
		width: calc(33.3333% - 1.875rem);
	}
}

@supports (grid-template-columns: repeat(2, 1fr)) {
	@media (min-width: 768px) {
		.post-grid--only-3 .post-grid__item {
			margin-right: 0;
			margin-bottom: 0;
			width: auto;
		}
	}
}

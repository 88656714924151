/*
 * Styleguide CSS
 *
 * This stylesheets only functions for styleguide purpose, and should not be
 * used inside the Orakel Platform.
 */

/**
 * Intro
 */
.sg-intro {
	padding: 100px 20px;
	text-align: center;
}

.sg-intro p {
	color: #939393;
	margin-right: auto;
	margin-left: auto;
	max-width: 600px;
}

.sg-intro__logo {
	display: inline-block;
	width: 200px;
}

.sg-intro__logo svg {
	width: 100%;
}

.sg-intro__title {
	font-family: monospace;
	margin-bottom: 1em;
}

/**
 * Section
 */
.sg-section {
	border-top: 1px solid rgb(230 230 230);
	overflow: hidden;
	overflow-y: auto;
	padding-top: 3rem;
	padding-bottom: 4rem;
	max-width: 100%;
}

.sg-section__title {
	font-family: monospace;
	padding-right: 1rem;
	padding-bottom: 1rem;
	padding-left: 1rem;
	position: relative;
	text-align: center;
}

.sg-section__title a {
	border: none;
	color: black;
	position: relative;
	text-decoration: none;
}

.sg-section__title a::before {
	color: rgb(200 200 200);
	content: "∞";
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(-150%, -50%);
	transition: opacity 303ms;
}

.sg-section__title:hover a {
	color: black;
}

.sg-section__title:hover a::before {
	opacity: 1;
}

.sg-section__comment {
	padding-right: 1rem;
	padding-bottom: 1rem;
	padding-left: 1rem;
	position: relative;
	text-align: center;
}

.sg-divider {
	background: none;
	border: none;
	border-top: 1px solid rgb(230 230 230);
	margin: 2rem auto;
	height: 0;
}

/**
 * Wrapper
 */
.sg-wrapper {
	margin: 0 auto;
	padding: 20px;
	max-width: 740px;
}

.sg-wrapper--centered {
	text-align: center;
}

/**
 * Colors
 */
.sg-colors {
	display: flex;
	flex-wrap: wrap;
	text-align: center;
}

.sg-colors__color {
	flex-basis: 50%;
	margin-bottom: 20px;
}

@media (min-width: 768px) {
	.sg-colors__color {
		flex-basis: 33%;
	}
}

.sg-colors__color__sample {
	border-radius: 50%;
	display: inline-block;
	margin: 0;
	padding: 0;
	width: 35px;
	height: 35px;
}

.sg-colors__color__sample--bordered {
	border: 1px solid #939393;
}

.sg-colors__color__name {
	font-family: monospace;
	text-transform: lowercase;
}

/**
 * Navigation
 */
#sg-nav__checkbox {
	display: none;
}

.sg-nav__drawer {
	background-color: #f0f0f0;
	border-right: 1px solid #c5c4c5;
	box-sizing: border-box;
	overflow: auto;
	padding: 5.25rem 1.25rem 1.25rem;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	transform: translateX(-250px);
	width: 250px;
	z-index: 998;
}

@media (min-width: 768px) {
	.sg-nav__drawer {
		padding: 6.25rem 2.25rem 2.25rem;
		transform: translateX(-33.3333vw);
		width: 33.3333vw;
	}
}

#sg-nav__checkbox:checked ~ .sg-nav__drawer {
	transform: translate(0, 0);
}

.sg-nav__close {
	background-color: rgb(0 0 0 / 0);
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 997;
}

.sg-nav__close:hover {
	background-color: rgb(0 0 0 / 0.2);
	cursor: pointer;
}

#sg-nav__checkbox:checked ~ .sg-nav__close {
	display: block;
}

.sg-nav__toggle {
	background-color: #fff799;
	color: black;
	cursor: pointer;
	font-size: 1.125rem;
	line-height: 1.25;
	position: fixed;
	text-align: center;
	top: 1.25rem;
	left: 1.25rem;
	z-index: 999;
}

@media (min-width: 768px) {
	.sg-nav__toggle {
		top: 2.25rem;
		left: 2.25rem;
	}
}

@media (min-width: 1240px) {
	.sg-nav__toggle {
		font-size: 1.5rem;
	}
}

.sg-nav__toggle::before {
	content: "Show";
}

#sg-nav__checkbox:checked ~ .sg-nav__toggle {
	background-color: transparent;
	color: #939393;
}

#sg-nav__checkbox:checked ~ .sg-nav__toggle::before {
	content: "Hide";
}

#sg-nav__checkbox:checked ~ .sg-nav__close:hover ~ .sg-nav__toggle {
	background-color: #fff799;
}

/**
 * Code
 */
.sg-code code {
	background-color: rgb(0 0 0 / 0.1);
	display: block;
	font-size: 14px;
	padding: 20px;
}

.sg-code code:not(:last-child) {
	padding: 20px 20px 0 20px;
}

.sg-placeholder {
	background-color: rgb(0 0 0 / 0.25);
	width: 10rem;
	max-width: 75%;
	height: 5rem;
}

.sg-placeholder--large {
	height: 10rem;
}

.product-teaser {
	box-sizing: border-box;
	color: currentColor;
	display: block;
	padding: 10vw 10%;
	text-decoration: none;
	width: 100%;
}

@media (min-width: 768px) {
	.product-teaser {
		display: flex;
		padding: 4rem 12.5%;
	}
}

@media (min-width: 1024px) {
	.product-teaser {
		padding-top: 5.5rem;
		padding-bottom: 5.5rem;
	}
}

@media (min-width: 1380px) {
	.product-teaser {
		padding-top: 6.875rem;
		padding-bottom: 6.875rem;
	}
}

@media (min-width: 1680px) {
	.product-teaser {
		padding-right: calc((100% - 1260px) / 2);
		padding-left: calc((100% - 1260px) / 2);
	}
}

@media (min-width: 768px) {
	.product-teaser__header,
	.product-teaser__info {
		display: flex;
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		align-items: center;
	}
}

@media (max-width: 767px) {
	.product-teaser__header {
		margin-bottom: 1rem;
	}
}

@media (min-width: 1380px) {
	.product-teaser__header {
		position: relative;
	}
}

@media (min-width: 1280px) {
	.product-teaser__info__wrapper {
		box-sizing: border-box;
		padding-left: 3rem;
	}
}

@media (min-width: 1380px) {
	.product-teaser__info__wrapper {
		padding-left: 6.75rem;
	}
}

@media (min-width: 1680px) {
	.product-teaser__info__wrapper {
		padding-left: 15.75rem;
	}
}

.product-teaser__title {
	font-family: var(--font-title);
	font-size: 3.125rem;
	line-height: 1;
	margin-top: 0;
	margin-bottom: 0;
	text-transform: none;
}

@media (max-width: 1379px) {
	.product-teaser__title {
		margin-bottom: 1rem;
	}
}

@media (min-width: 768px) {
	.product-teaser__title {
		font-size: 3.75rem;
	}
}

@media (min-width: 1280px) {
	.product-teaser__title {
		font-size: 4.5rem;
	}
}

@media (min-width: 1380px) {
	.product-teaser__title {
		font-size: 5rem;
		opacity: 1;
		transition: opacity 128ms linear;
	}
}

@media (min-width: 1680px) {
	.product-teaser__title {
		font-size: 6.25rem;
	}
}

@media (min-width: 1380px) {
	.product-teaser:hover .product-teaser__title {
		opacity: 0.15;
	}
}

.t-expro .product-teaser__title {
	color: var(--color-blue);
}

.t-express .product-teaser__title {
	color: var(--color-red);
}

.t-xplay .product-teaser__title {
	color: var(--color-warm-grey);
}

.t-xtrail .product-teaser__title {
	color: var(--color-purple);
}

.product-teaser__description {
	pointer-events: none;
}

@media (min-width: 768px) {
	.product-teaser__description {
		max-width: 420px;
	}
}

@media (min-width: 1380px) {
	.product-teaser__description {
		box-sizing: border-box;
		opacity: 0;
		padding-left: 1rem;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(calc(-50% + 30px));
		transform-origin: 50% 50%;
		transition: none;
		width: 100%;
		height: auto;
		z-index: 2;
	}
}

@media (min-width: 1380px) {
	.product-teaser:hover .product-teaser__description {
		opacity: 1;
		transform: translateY(-50%);
		transition:
			opacity 160ms 96ms linear,
			transform 368ms var(--ease-in-out);
	}
}

.product-teaser__media {
	display: block;
	overflow: hidden;
	pointer-events: none;
	position: relative;
	transform: scale3d(1.2, 1.2, 1);
	width: 100%;
	z-index: -1;
}

@media (min-width: 768px) {
	.product-teaser__media {
		margin-right: auto;
		margin-left: auto;
		transition:
			opacity 128ms linear,
			transform 368ms var(--ease-in-out);
		width: auto;
		max-width: 100%;
		max-height: 200px;
	}
}

@media (min-width: 1024px) {
	.product-teaser__media {
		transform: scale3d(1.4, 1.4, 1);
		max-height: 240px;
	}
}

@media (min-width: 1380px) {
	.product-teaser__media {
		transform: scale3d(1.7, 1.7, 1);
	}
}

@media (min-width: 768px) {
	.product-teaser:hover .product-teaser__media {
		transform: translateZ(0);
	}
}

@media (min-width: 1024px) {
	.product-teaser:hover .product-teaser__media {
		transform: scale3d(1.3, 1.3, 1);
	}
}

@media (min-width: 1380px) {
	.product-teaser:hover .product-teaser__media {
		transform: scale3d(1.5, 1.5, 1);
	}
}

.product-teaser__image,
.product-teaser__video {
	width: 100%;
}

.product-teaser__video {
	opacity: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform-origin: 50% 50%;
	transition: none;
	width: 100%;
	height: auto;
	z-index: 1;
}

.wrapper {
	box-sizing: border-box;
	padding-right: 10%;
	padding-left: 10%;
	width: 100%;
}

@media (min-width: 768px) {
	.wrapper {
		padding-right: 12.5%;
		padding-left: 12.5%;
	}
}

@media (min-width: 1680px) {
	.wrapper {
		padding-right: calc((100% - 1260px) / 2);
		padding-left: calc((100% - 1260px) / 2);
	}
}

.wrapper.wrapper--large {
	padding-right: 5%;
	padding-left: 5%;
}

@media (min-width: 1680px) {
	.wrapper.wrapper--large {
		padding-right: calc((100% - 1512px) / 2);
		padding-left: calc((100% - 1512px) / 2);
	}
}

.wrapper.wrapper--news-post-small {
	padding-right: 1rem;
	padding-left: 1rem;
}

@media (min-width: 562px) {
	.wrapper.wrapper--news-post-small {
		padding-right: calc((100% - 530px) / 2);
		padding-left: calc((100% - 530px) / 2);
	}
}

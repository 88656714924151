.pagination {
	color: black;
	display: flex;
	flex-wrap: wrap;
	font-size: 1.125rem;
	list-style: none;
	margin: 0;
	justify-content: center;
	align-items: center;
	width: 100%;
}

@media (min-width: 1380px) {
	.pagination {
		font-size: 1.5rem;
	}
}

.grid + .pagination {
	margin-top: 1.5625rem;
}

@media (min-width: 1024px) {
	.grid + .pagination {
		margin-top: 1.875rem;
	}
}

@media (min-width: 1380px) {
	.grid + .pagination {
		margin-top: 2rem;
	}
}

.pagination__link {
	color: black;
	display: block;
	overflow: visible;
	position: relative;
	text-decoration: none;
	transition: color 96ms linear;
	width: 100%;
}

.pagination__link:not(.pagination__link--active) {
	opacity: 0.5;
	pointer-events: none;
}

.pagination__link:not(.pagination__link--active):hover {
	cursor: default;
}

.pagination__link:focus,
.pagination__link:hover {
	color: black;
}

.pagination-link__arrow {
	display: inline-block;
	transition: transform 256ms ease-out;
}

.pagination__link--left:focus .pagination-link__arrow,
.pagination__link--left:hover .pagination-link__arrow {
	transform: translateX(-3px);
}

.pagination__link--right:focus .pagination-link__arrow,
.pagination__link--right:hover .pagination-link__arrow {
	transform: translateX(3px);
}

.pagination__pages-container {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 1;
	margin-right: 1rem;
	margin-left: 1rem;
	text-align: center;
	max-width: calc(100% - 7.75rem);
}

.pagination__pages {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
	align-items: stretch;
	width: 100%;
}

.pagination__pages__item {
	margin: 0.5rem;
}

@media (min-width: 768px) {
	.pagination__pages__item {
		margin-top: 0;
		margin-bottom: 0;
	}
}

@media (min-width: 1024px) {
	.pagination__pages__item {
		margin-right: 0.625rem;
		margin-left: 0.625rem;
	}
}

@media (min-width: 1024px) {
	.pagination__pages__item:first-child {
		margin-left: 0;
	}
}

@media (min-width: 1024px) {
	.pagination__pages__item:last-child {
		margin-right: 0;
	}
}

@media (min-width: 1024px) {
	.pagination__pages__item:only-child {
		margin-right: 0;
		margin-left: 0;
	}
}

.pagination__pages__current-page-label,
.pagination__pages__item {
	display: inline-block;
	font-family: var(--font-title);
	font-weight: var(--font-weight-bold);
}

.pagination__pages__current-page-label,
.pagination__pages__link {
	line-height: 1;
}

.pagination__pages__current-page-label {
	color: var(--color-grey);
}

.pagination__pages__link {
	color: black;
	text-decoration: none;
	transition: opacity 96ms linear;
}

.pagination__pages__link:focus,
.pagination__pages__link:hover {
	color: black;
}

.pagination__pages__current-page-label:hover {
	cursor: default;
}

@media (min-width: 768px) {
	.news-posts-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

@media (min-width: 768px) {
	.news-posts-header__content-container,
	.news-posts-header__title-container {
		flex-basis: calc(50% - 2.5rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.news-posts-header__title-container {
	overflow: hidden;
}

@media (max-width: 767px) {
	.news-posts-header__title-container {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
	}
}

.news-posts-header__format-link {
	color: currentColor;
	font-family: var(--font-title);
	font-size: 1.125rem;
	font-weight: var(--font-weight-bold);
	line-height: normal;
	text-transform: uppercase;
}

.theme-white {
	background-color: var(--color-white);
	color: var(--color-black);
}

.theme-black {
	background-color: var(--color-black);
	color: var(--color-white);
}

.theme-blue {
	background-color: var(--color-blue);
	color: var(--color-white);
}

.theme-purple {
	background-color: var(--color-purple);
	color: var(--color-white);
}

.theme-red {
	background-color: var(--color-red);
	color: var(--color-white);
}

.theme-warm-grey {
	background-color: var(--color-warm-grey);
	color: var(--color-white);
}

.theme-text-white {
	color: var(--color-white);
}

.theme-text-black {
	color: var(--color-black);
}

.theme-text-blue {
	color: var(--color-blue);
}

.theme-text-purple {
	color: var(--color-purple);
}

.theme-text-red {
	color: var(--color-red);
}

.theme-text-warm-grey {
	color: var(--color-warm-grey);
}

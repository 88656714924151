@keyframes ANIMATE-IN-ICON {
	0% {
		opacity: 0;
		transform: translateY(-3rem) translateZ(0);
	}

	100% {
		opacity: 1;
		transform: translateZ(0);
	}
}

.letter-grid {
	color: var(--color-grey-medium);
}

.t-expro .letter-grid {
	color: var(--color-blue);
}

.t-express .letter-grid {
	color: var(--color-red);
}

.t-xplay .letter-grid {
	color: var(--color-warm-grey);
}

.t-xtrail .letter-grid {
	color: var(--color-purple);
}

.hero .letter-grid {
	color: white;
}

.hero--light .letter-grid {
	color: black;
}

.t-expro .hero--brand .letter-grid {
	color: var(--color-blue);
}

.t-express .hero--brand .letter-grid {
	color: var(--color-red);
}

.t-xplay .hero--brand .letter-grid {
	color: var(--color-warm-grey);
}

.t-xtrail .hero--brand .letter-grid {
	color: var(--color-purple);
}

.letter-grid__row {
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

@media (min-width: 768px) {
	.letter-grid__row:nth-child(1n + 2) {
		padding-left: 10rem;
	}
}

@media (min-width: 1024px) {
	.letter-grid__row:nth-child(1n + 2) {
		padding-left: 9.5rem;
	}
}

@media (min-width: 1380px) {
	.letter-grid__row:nth-child(1n + 2) {
		padding-left: 12.5rem;
	}
}

.letter-grid__letter {
	color: currentColor;
	display: flex;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	width: 5rem;
	height: 5rem;
}

@media (min-width: 568px) {
	.letter-grid__letter {
		width: 6.25rem;
		height: 6.25rem;
	}
}

@media (min-width: 768px) {
	.letter-grid__letter {
		width: 10rem;
		height: 10rem;
	}
}

@media (min-width: 1024px) {
	.letter-grid__letter {
		margin: -0.5rem -1.5rem;
		width: 13rem;
		height: 13rem;
	}
}

@media (min-width: 1380px) {
	.letter-grid__letter {
		margin: -1rem -2rem;
		width: 17rem;
		height: 17rem;
	}
}

@media (min-width: 1024px) {
	.hero .letter-grid__letter {
		margin: 0 -1rem;
		width: 8rem;
		height: 8rem;
	}
}

@media (min-width: 1380px) {
	.hero .letter-grid__letter {
		margin: -0.5rem -1.5rem;
		width: 12rem;
		height: 12rem;
	}
}

.letter-grid__letter .icon {
	animation: ANIMATE-IN-ICON 400ms var(--ease-in-out-quad) 1440ms forwards;
	display: block;
	fill: currentColor;
	opacity: 0;
	transform: translateY(-3rem) translateZ(0);
	width: 100%;
	height: 100%;
}

.letter-grid__letter.letter-grid__letter--1 .icon {
	animation-delay: 288ms;
}

.letter-grid__letter.letter-grid__letter--2 .icon {
	animation-delay: 576ms;
}

.letter-grid__letter.letter-grid__letter--3 .icon {
	animation-delay: 864ms;
}

.letter-grid__letter.letter-grid__letter--4 .icon {
	animation-delay: 1152ms;
}

.letter-grid__letter.letter-grid__letter--5 .icon {
	animation-delay: 1296ms;
}

.letter-grid__letter.letter-grid__letter--6 .icon {
	animation-delay: 1440ms;
}

@media (min-width: 1024px) {
	.letter-grid__letter.letter-grid__letter--I,
	.letter-grid__letter.letter-grid__letter--plus {
		margin: -0.5rem -2rem;
	}
}

@media (min-width: 1380px) {
	.letter-grid__letter.letter-grid__letter--I,
	.letter-grid__letter.letter-grid__letter--plus {
		margin: -1rem -3rem;
	}
}

@media (min-width: 1024px) {
	.hero .letter-grid__letter.letter-grid__letter--I,
	.hero .letter-grid__letter.letter-grid__letter--plus {
		margin: 0 -1rem;
	}
}

@media (min-width: 1380px) {
	.hero .letter-grid__letter.letter-grid__letter--I,
	.hero .letter-grid__letter.letter-grid__letter--plus {
		margin: -0.5rem -2rem;
	}
}

@media (min-width: 1024px) {
	.letter-grid__letter.letter-grid__letter--M:first-child,
	.letter-grid__letter.letter-grid__letter--W:first-child {
		transform: translateX(-2rem);
	}
}

@media (min-width: 1380px) {
	.letter-grid__letter.letter-grid__letter--M:first-child,
	.letter-grid__letter.letter-grid__letter--W:first-child {
		transform: translateX(-3rem);
	}
}

@media (min-width: 1024px) {
	.hero .letter-grid__letter.letter-grid__letter--M:first-child,
	.hero .letter-grid__letter.letter-grid__letter--W:first-child {
		transform: translateX(-1rem);
	}
}

@media (min-width: 1380px) {
	.hero .letter-grid__letter.letter-grid__letter--M:first-child,
	.hero .letter-grid__letter.letter-grid__letter--W:first-child {
		transform: translateX(-2rem);
	}
}

@media (min-width: 1024px) {
	.letter-grid__letter.letter-grid__letter--M:not(:first-child):not(:last-child),
	.letter-grid__letter.letter-grid__letter--W:not(:first-child):not(:last-child) {
		margin-right: 0;
		margin-left: 0;
	}
}

@media (min-width: 1024px) {
	.letter-grid__letter.letter-grid__letter--M:last-child,
	.letter-grid__letter.letter-grid__letter--W:last-child {
		transform: translateX(2rem);
	}
}

@media (min-width: 1380px) {
	.letter-grid__letter.letter-grid__letter--M:last-child,
	.letter-grid__letter.letter-grid__letter--W:last-child {
		transform: translateX(3rem);
	}
}

@media (min-width: 1024px) {
	.hero .letter-grid__letter.letter-grid__letter--M:last-child,
	.hero .letter-grid__letter.letter-grid__letter--W:last-child {
		transform: translateX(1rem);
	}
}

@media (min-width: 1380px) {
	.hero .letter-grid__letter.letter-grid__letter--M:last-child,
	.hero .letter-grid__letter.letter-grid__letter--W:last-child {
		transform: translateX(2rem);
	}
}

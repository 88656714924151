@media (min-width: 768px) {
	.text-content__wrapper {
		display: flex;
		justify-content: flex-end;
	}
}

@media (min-width: 768px) {
	.text-content__content,
	.text-content__title {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (max-width: 767px) {
	.text-content__title:not(:only-child) {
		margin-bottom: 2.5rem;
	}
}

@media (min-width: 768px) {
	.text-content__title {
		box-sizing: border-box;
		padding-right: 10%;
	}
}

@media (min-width: 1024px) {
	.text-content__title {
		padding-right: 15%;
	}
}

@media (min-width: 768px) {
	.text-content__title:only-child {
		flex-basis: 100%;
		padding-right: 0;
	}
}

.text-content__title__title {
	font-size: 1rem;
	text-transform: none;
}

@media (min-width: 768px) {
	.text-content__title__title {
		font-size: 1.375rem;
	}
}

@media (min-width: 1024px) {
	.text-content__title__title {
		font-size: 1.875rem;
	}
}

@media (min-width: 768px) {
	.text-content__content:not(:only-child) {
		box-sizing: border-box;
		padding-top: 0.75rem;
	}
}

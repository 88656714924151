body main {
	background-color: white;
	display: block;
	position: relative;
	min-height: 100vh;
	z-index: 2;
}

body.has-story-resolved {
	scroll-snap-points-y: repeat(100vh);
	/* stylelint-disable-next-line declaration-property-value-no-unknown -- broken, must be fixed by a human */
	scroll-snap-type: proximity;
	scroll-snap-type: y proximity;
}

ol:not([class]),
ul:not([class]) {
	list-style: none;
	margin: 0 0 1rem 0;
	padding-left: 1rem;
}

@media (min-width: 1024px) {
	ol:not([class]),
	ul:not([class]) {
		margin-bottom: 2rem;
	}
}

ol:not([class]):last-child,
ul:not([class]):last-child {
	margin-bottom: 0;
}

ol:not([class]):only-child,
ul:not([class]):only-child {
	margin-top: 0;
	margin-bottom: 0;
}

ol:not([class]) li,
ul:not([class]) li {
	line-height: 1.75;
}

li ol:not([class]),
li ul:not([class]) {
	margin-bottom: 0;
}

ol:not([class]) {
	counter-reset: ol-list-items;
	padding-left: 1rem;
}

@media (min-width: 1024px) {
	ol:not([class]) {
		padding-left: 1.5rem;
	}
}

ol:not([class]) ol:not([class]) {
	counter-reset: ol-sub-list-items;
	padding-left: 1.5rem;
}

@media (min-width: 1024px) {
	ol:not([class]) ol:not([class]) {
		padding-left: 2.375rem;
	}
}

li:not([class]) {
	line-height: 1.6154;
	margin: 0;
	padding: 0;
	position: relative;
}

@media (min-width: 1024px) {
	li:not([class]) {
		line-height: 1.5;
	}
}

li:not([class]):last-child {
	margin: 0;
}

li:not([class])::before {
	position: absolute;
}

ul:not([class]) li:not([class])::before {
	background-color: black;
	border-radius: 0.25rem;
	content: "";
	display: block;
	top: 0.5625rem;
	left: -0.875rem;
	width: 0.25rem;
	height: 0.25rem;
}

@media (min-width: 1024px) {
	ul:not([class]) li:not([class])::before {
		top: 0.9375rem;
	}
}

ol:not([class]) li:not([class])::before {
	color: black;
	content: counter(ol-list-items);
	counter-increment: ol-list-items;
	font-size: 0.9375rem;
	font-weight: var(--font-weight-normal);
	line-height: 1.6154;
	top: 0;
	left: -1rem;
}

@media (min-width: 1024px) {
	ol:not([class]) li:not([class])::before {
		font-size: 1.25rem;
		line-height: 1.5;
		top: 2px;
		left: -1.5rem;
	}
}

ol:not([class]) ol:not([class]) li:not([class])::before {
	content: counter(ol-list-items) "." counter(ol-sub-list-items);
	counter-increment: ol-sub-list-items;
	left: -1.5rem;
}

@media (min-width: 1024px) {
	ol:not([class]) ol:not([class]) li:not([class])::before {
		left: -2.375rem;
	}
}

img,
video {
	display: block;
	max-width: 100%;
}

table,
th,
td {
	border: 1px solid black;
}

table {
	border-collapse: collapse;
	font-size: 0.9375rem;
	line-height: 1.6154;
	margin: 1rem 0;
	width: 100%;
	max-width: 100%;
}

@media (min-width: 768px) {
	table {
		font-size: 1rem;
		line-height: 1.5;
	}
}

@media (min-width: 1024px) {
	table {
		font-size: 1.25rem;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
}

table:first-child {
	margin-top: 0;
}

table:last-child {
	margin-bottom: 0;
}

th,
td {
	padding: 1rem;
	text-align: left;
}

@media (min-width: 768px) {
	th,
	td {
		padding: 1rem 1.875rem;
	}
}

th {
	font-weight: var(--font-weight-bold);
}

td {
	font-weight: var(--font-weight-normal);
}

dl {
	margin: 0 0 2rem 0;
}

dl:last-child {
	margin-bottom: 0;
}

dt,
th {
	font-size: 0.75rem;
	letter-spacing: 1px;
	line-height: 1.5833;
	text-transform: uppercase;
}

@media (min-width: 1024px) {
	dt,
	th {
		font-size: 0.875rem;
	}
}

dt {
	font-weight: var(--font-weight-normal);
}

dd {
	margin: 0;
	padding: 1rem 0;
}

hr {
	background-color: black;
	border: none;
	margin-bottom: 1.25rem;
	height: 1px;
}

@media (min-width: 1024px) {
	hr {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}
}

@media (max-width: 567px) {
	.g-recaptcha {
		transform: scale(0.85);
		transform-origin: 0 0;
	}
}

.l-enquire {
	padding-top: 6.25rem;
}

@media (min-width: 768px) {
	.l-enquire {
		padding-top: 8rem;
		padding-bottom: 4rem;
	}
}

@media (max-width: 1023px) {
	.is-scrolling-up .l-enquire {
		padding-top: 9.375rem;
	}
}

@media (min-width: 768px) {
	.l-enquire__wrapper {
		display: flex;
	}
}

.l-enquire__icon {
	color: black;
}

@media (max-width: 767px) {
	.l-enquire__icon {
		margin-bottom: 2rem;
	}
}

@media (min-width: 768px) {
	.l-enquire__icon {
		flex-basis: 40%;
		flex-shrink: 0;
	}
}

@media (min-width: 1024px) {
	.l-enquire__icon {
		flex-basis: 50%;
	}
}

.t-expro .l-enquire__icon {
	color: var(--color-blue);
}

.t-express .l-enquire__icon {
	color: var(--color-red);
}

.t-xplay .l-enquire__icon {
	color: var(--color-warm-grey);
}

.t-xtrail .l-enquire__icon {
	color: var(--color-purple);
}

.l-enquire__icon .icon {
	display: block;
	fill: currentColor;
	width: 10rem;
	height: 10rem;
}

@media (min-width: 1024px) {
	.l-enquire__icon .icon {
		width: 15rem;
		height: 15rem;
	}
}

@media (min-width: 1280px) {
	.l-enquire__icon .icon {
		width: 20rem;
		height: 20rem;
	}
}

@media (min-width: 1680px) {
	.l-enquire__icon .icon {
		width: 30rem;
		height: 30rem;
	}
}

@media (min-width: 768px) {
	.l-enquire__content {
		flex-grow: 1;
		padding-top: 3rem;
	}
}

@media (min-width: 1024px) {
	.l-enquire__content {
		padding-top: 4rem;
	}
}

@media (min-width: 1280px) {
	.l-enquire__content {
		padding-top: 5rem;
	}
}

@media (min-width: 1680px) {
	.l-enquire__content {
		padding-top: 6rem;
	}
}

.info-block {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
}

@media (min-width: 768px) {
	.info-block {
		display: flex;
		align-items: stretch;
	}
}

.info-block.info-block:nth-child(even):not(.info-block--right),
.info-block.info-block--left {
	background-color: var(--color-blue);
	color: white;
}

@media (min-width: 768px) {
	.info-block--left.is-playing-video .info-block__content-wrapper,
	.info-block--left.is-playing-video .info-block__media-wrapper {
		transform: translateX(-45vw);
	}
}

@media (min-width: 1024px) {
	.info-block--left.is-playing-video .info-block__content-wrapper,
	.info-block--left.is-playing-video .info-block__media-wrapper {
		transform: translateX(-38.25vw);
	}
}

.info-block__media-wrapper {
	background-color: black;
	overflow: hidden;
	position: relative;
	transition: opacity 160ms linear;
	z-index: 2;
}

.info-block__media-wrapper.is-faded {
	opacity: 0;
}

@media (max-width: 767px) {
	.info-block__media-wrapper {
		height: 56.25vw;
	}
}

@media (min-width: 768px) {
	.info-block__media-wrapper {
		flex-basis: 55%;
		flex-grow: 0;
		flex-shrink: 0;
		transition:
			flex-basis 720ms 400ms cubic-bezier(0.5, 0, 0.125, 1),
			max-width 720ms 400ms cubic-bezier(0.5, 0, 0.125, 1),
			min-height 720ms 400ms cubic-bezier(0.5, 0, 0.125, 1),
			opacity 160ms linear;
		max-width: 55%;
	}
}

@media (min-width: 1024px) {
	.info-block__media-wrapper {
		flex-basis: 70%;
		max-width: 70%;
	}
}

@media (max-width: 1379px) {
	.info-block__media-wrapper {
		min-height: 44vw;
	}
}

@media (min-width: 1380px) {
	.info-block__media-wrapper {
		min-height: 700px;
	}
}

@media (min-width: 768px) {
	.info-block:nth-child(even):not(.info-block--right) .info-block__media-wrapper,
	.info-block--left .info-block__media-wrapper {
		transition:
			flex-basis 720ms 400ms cubic-bezier(0.5, 0, 0.125, 1),
			max-width 720ms 400ms cubic-bezier(0.5, 0, 0.125, 1),
			min-height 720ms 400ms cubic-bezier(0.5, 0, 0.125, 1),
			opacity 160ms linear,
			transform 720ms 400ms cubic-bezier(0.5, 0, 0.125, 1);
	}
}

@media (min-width: 768px) {
	.info-block.is-playing-video .info-block__media-wrapper {
		max-width: 100%;
		min-height: 56.25vw;
	}
}

.info-block__media-wrapper__media {
	display: block;
	width: 100%;
}

@supports (object-fit: cover) {
	.info-block__media-wrapper__media {
		object-fit: cover;
		object-position: center center;
		height: 56.25vw;
	}

	@media (min-width: 768px) {
		.info-block__media-wrapper__media {
			position: absolute;
			height: 100%;
		}
	}
}

.info-block__content-wrapper {
	box-sizing: border-box;
	color: currentColor;
	padding: 10vw 10%;
}

@media (min-width: 768px) {
	.info-block__content-wrapper {
		padding: 12.5vw 33.3333% 12.5vw 12.5%;
	}
}

@media (min-width: 768px) {
	.info-block__content-wrapper {
		display: flex;
		flex-basis: 45%;
		flex-grow: 0;
		flex-shrink: 0;
		flex-direction: column;
		padding: 3rem;
		justify-content: space-between;
		align-items: flex-start;
		max-width: 45%;
	}
}

@media (min-width: 1024px) {
	.info-block__content-wrapper {
		flex-basis: 30%;
		max-width: 30%;
	}
}

@media (min-width: 1380px) {
	.info-block__content-wrapper {
		padding: 4rem;
	}
}

@media (min-width: 1680px) {
	.info-block__content-wrapper {
		padding-right: calc(30% - 408px);
	}
}

@media (min-width: 768px) {
	.info-block:nth-child(even):not(.info-block--right) .info-block__content-wrapper,
	.info-block--left .info-block__content-wrapper {
		order: -1;
		transition: transform 720ms 400ms cubic-bezier(0.5, 0, 0.125, 1);
	}
}

@media (min-width: 1680px) {
	.info-block:nth-child(even):not(.info-block--right) .info-block__content-wrapper,
	.info-block--left .info-block__content-wrapper {
		padding-right: 4rem;
		padding-left: calc(30% - 408px);
	}
}

.info-block__content-wrapper__content {
	display: block;
	position: relative;
	max-width: 100%;
	z-index: 1;
}

@media (min-width: 768px) {
	.info-block__content-wrapper__content {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.info-block__content-wrapper__call-to-action {
	border-bottom-color: currentColor;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	color: currentColor;
	display: inline-block;
	font-weight: var(--font-weight-bold);
	line-height: 1.5;
	margin-top: 2rem;
	transition: border-bottom-color 96ms linear;
	max-width: 100%;
}

.info-block__content-wrapper__call-to-action:hover {
	border-bottom-color: rgb(0 0 0 / 0);
}

@media (min-width: 768px) {
	.info-block__content-wrapper__call-to-action {
		margin-top: 3rem;
	}
}

@media (min-width: 768px) {
	.info-block__content-wrapper__call-to-action {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

a.info-block__content-wrapper__call-to-action {
	text-decoration: none;
}

button.info-block__content-wrapper__call-to-action {
	appearance: none;
	background: none;
	border-top: 0;
	border-right: 0;
	border-left: 0;
	border-radius: 0;
	box-shadow: none;
	cursor: pointer;
	outline: 0;
	padding: 0;
	transition: opacity 160ms linear;
}

button.info-block__content-wrapper__call-to-action::after {
	content: attr(label-initial);
}

button.info-block__content-wrapper__call-to-action.is-loading,
button.info-block__content-wrapper__call-to-action.has-loaded {
	border-bottom: 0;
	cursor: default;
	pointer-events: none;
}

button.info-block__content-wrapper__call-to-action.is-loading::after {
	content: attr(label-loading);
}

button.info-block__content-wrapper__call-to-action.has-loaded {
	opacity: 0;
}

button.info-block__content-wrapper__call-to-action.is-hidden {
	visibility: hidden;
}

.info-block__close-button {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	color: white;
	cursor: default;
	font-size: 2rem;
	line-height: 1;
	opacity: 0;
	outline: 0;
	padding: 0;
	pointer-events: none;
	position: absolute;
	top: 3rem;
	right: 3rem;
	transition: opacity 160ms 160ms linear;
	z-index: 3;
}

@media (max-width: 767px) {
	.info-block__close-button {
		display: none;
	}
}

@media (min-width: 768px) {
	.info-block.is-playing-video .info-block__close-button {
		cursor: pointer;
		opacity: 1;
		pointer-events: auto;
		transition: opacity 160ms 1120ms linear;
	}
}

.info-block__title {
	margin-top: 0;
	margin-bottom: 1.5625rem;
}

.info-block__icon {
	display: none;
}

@media (min-width: 768px) {
	.info-block__icon {
		display: block;
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 0;
		opacity: 0;
		position: relative;
		transition: opacity 120ms linear;
	}

	.info-block:hover .info-block__icon {
		opacity: 1;
	}
}

.info-block__icon .info-block.is-playing-video {
	display: none;
}

.info-block__icon .icon {
	display: block;
	fill: currentColor;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 42px;
	height: 35px;
}

.accordion {
}

.accordion__list {
	display: block;
	width: 100%;
}

.accordion:not(.accordion--bordered) .accordion__item:not(:last-child) {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

.accordion--bordered .accordion__item {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}

@media (min-width: 768px) {
	.accordion--bordered .accordion__item {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (min-width: 1024px) {
	.accordion--bordered .accordion__item {
		padding-top: 5.5rem;
		padding-bottom: 5.5rem;
	}
}

@media (min-width: 1380px) {
	.accordion--bordered .accordion__item {
		padding-top: 6.875rem;
		padding-bottom: 6.875rem;
	}
}

.accordion--bordered .accordion__item:not(:last-child) {
	border-bottom-color: var(--color-faded-black);
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.t-expro .accordion--bordered .accordion__item:not(:last-child) {
	border-bottom-color: var(--color-faded-blue);
}

.t-express .accordion--bordered .accordion__item:not(:last-child) {
	border-bottom-color: var(--color-faded-red);
}

.t-xplay .accordion--bordered .accordion__item:not(:last-child) {
	border-bottom-color: var(--color-faded-warm-grey);
}

.t-xtrail .accordion--bordered .accordion__item:not(:last-child) {
	border-bottom-color: var(--color-faded-purple);
}

.accordion__radio {
	display: none;
}

.accordion__trigger__label {
	box-sizing: border-box;
	color: black;
	display: block;
	font-family: var(--font-title);
	font-size: 0.875rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.5833;
	padding-right: 25%;
	position: relative;
	text-transform: uppercase;
	width: 100%;
}

@media (min-width: 1024px) {
	.accordion__trigger__label {
		font-size: 1.25rem;
	}
}

.accordion__trigger__label:hover {
	cursor: pointer;
}

.accordion__trigger--large .accordion__trigger__label {
	font-size: 1.5625rem;
	line-height: 1.08;
}

@media (min-width: 1024px) {
	.accordion__trigger--large .accordion__trigger__label {
		font-size: 2.5rem;
	}
}

@media (min-width: 1380px) {
	.accordion__trigger--large .accordion__trigger__label {
		font-size: 4rem;
	}
}

.t-expro .accordion__trigger__label,
#accordion-item-expro .accordion__radio:checked ~ .accordion__trigger .accordion__trigger__label {
	color: var(--color-blue);
}

.t-express .accordion__trigger__label,
#accordion-item-express .accordion__radio:checked ~ .accordion__trigger .accordion__trigger__label {
	color: var(--color-red);
}

.t-xplay .accordion__trigger__label,
#accordion-item-xplay .accordion__radio:checked ~ .accordion__trigger .accordion__trigger__label {
	color: var(--color-warm-grey);
}

.t-xtrail .accordion__trigger__label,
#accordion-item-xtrail .accordion__radio:checked ~ .accordion__trigger .accordion__trigger__label {
	color: var(--color-purple);
}

.accordion__trigger__label .icon {
	display: block;
	fill: currentColor;
	position: absolute;
	top: 0.125rem;
	right: 0;
	transition: transform 320ms var(--ease-in-out);
	width: 1.125rem;
	height: 1.125rem;
}

@media (min-width: 1024px) {
	.accordion__trigger__label .icon {
		top: 0.25rem;
		width: 1.5rem;
		height: 1.5rem;
	}
}

.accordion__trigger--large .accordion__trigger__label .icon {
	top: 0.375rem;
}

@media (min-width: 1024px) {
	.accordion__trigger--large .accordion__trigger__label .icon {
		top: 0.75rem;
	}
}

@media (min-width: 1380px) {
	.accordion__trigger--large .accordion__trigger__label .icon {
		top: 1.5rem;
	}
}

.accordion__radio:checked ~ .accordion__trigger .accordion__trigger__label .icon {
	transform: rotate(180deg);
}

.accordion__content {
	display: none;
}

.accordion__radio:checked ~ .accordion__content {
	display: block;
	padding-top: 2.5rem;
}

@media (min-width: 768px) {
	.accordion__radio:checked ~ .accordion__content {
		padding-top: 4rem;
	}
}

@media (min-width: 1024px) {
	.accordion__radio:checked ~ .accordion__content {
		padding-top: 5.5rem;
	}
}

@media (min-width: 1380px) {
	.accordion__radio:checked ~ .accordion__content {
		padding-top: 6.875rem;
	}
}

#accordion-item-expro .accordion__radio:checked ~ .accordion__content {
	color: var(--color-blue);
}

#accordion-item-express .accordion__radio:checked ~ .accordion__content {
	color: var(--color-red);
}

#accordion-item-xplay .accordion__radio:checked ~ .accordion__content {
	color: var(--color-warm-grey);
}

#accordion-item-xtrail .accordion__radio:checked ~ .accordion__content {
	color: var(--color-purple);
}

mr-accordion.is-resolved .accordion__content[aria-hidden="true"] {
	opacity: 0;
	transform: scale(0.98);
}

.accordion__content[aria-hidden="false"] {
	opacity: 1;
	transition:
		opacity 128ms linear,
		transform 368ms var(--ease-in-out);
}

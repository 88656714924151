.product-teaser-list {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

.product-teaser-list__item {
	display: block;
}

.product-teaser-list__item:first-child {
	border-top: 1px solid var(--color-grey-medium);
}

.product-teaser-list__item:not(:last-child) {
	border-bottom: 1px solid var(--color-grey-medium);
}

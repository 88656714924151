.text-image-full-width {
	overflow: hidden;
}

.text-image-full-width__content {
	box-sizing: border-box;
	margin-right: auto;
	margin-left: auto;
	padding: 1.25rem;
	text-align: center;
	width: 100%;
	max-width: 500px;
}

@media (min-width: 1380px) {
	.text-image-full-width__content {
		padding: 1.875rem;
		max-width: 700px;
	}
}

.text-image-full-width__title {
	font-family: var(--font-title);
	font-size: 3.125rem;
	font-weight: var(--font-weight-bold);
	line-height: normal;
	margin-bottom: 1.25rem;
	opacity: 0;
	transform: translateY(25px);
	transition:
		opacity 240ms linear 128ms,
		transform 800ms cubic-bezier(0.635, 0, 0.025, 1);
}

@media (min-width: 768px) {
	.text-image-full-width__title {
		font-size: 3.75rem;
		margin-bottom: 1rem;
	}
}

@media (min-width: 1380px) {
	.text-image-full-width__title {
		font-size: 5.625rem;
		margin-bottom: 0;
	}
}

html.no-js .text-image-full-width__title,
.text-image-full-width.is-intersecting .text-image-full-width__title {
	opacity: 1;
	transform: translateZ(0);
}

.text-image-full-width__text {
	font-family: var(--font-title);
	font-size: 1.25rem;
	font-weight: var(--font-weight-bold);
	line-height: normal;
	margin-bottom: 1.25rem;
	opacity: 0;
	transform: translateY(10px);
	transition:
		opacity 272ms linear 432ms,
		transform 800ms cubic-bezier(0.635, 0, 0.025, 1) 208ms;
}

@media (min-width: 768px) {
	.text-image-full-width__text {
		font-size: 1.5rem;
	}
}

@media (min-width: 1380px) {
	.text-image-full-width__text {
		font-size: 1.875rem;
		margin-bottom: 1.875rem;
	}
}

html.no-js .text-image-full-width__text,
.text-image-full-width.is-intersecting .text-image-full-width__text {
	opacity: 1;
	transform: translateZ(0);
}

.text-image-full-width__links {
	opacity: 0;
	transform: translateY(5px);
	transition:
		opacity 272ms linear 544ms,
		transform 800ms cubic-bezier(0.635, 0, 0.025, 1) 416ms;
}

html.no-js .text-image-full-width__links,
.text-image-full-width.is-intersecting .text-image-full-width__links {
	opacity: 1;
	transform: translateZ(0);
}

.text-image-full-width__image {
	margin-right: auto;
	margin-left: auto;
	opacity: 0;
	transform: translateY(30px);
	transition:
		opacity 272ms linear 544ms,
		transform 800ms cubic-bezier(0.635, 0, 0.025, 1) 416ms;
}

html.no-js .text-image-full-width__image,
.text-image-full-width.is-intersecting .text-image-full-width__image {
	opacity: 1;
	transform: translateZ(0);
}

:root {
	--color-black: #000000;
	--color-blue: #618db4;
	--color-grey-light: #f8f8f8;
	--color-grey-medium: #e5e5e5;
	--color-grey: #b3b3b3;
	--color-purple: #866479;
	--color-red: #ff004f;
	--color-warm-grey: #aca199;
	--color-white: #ffffff;
	/*  */
	--color-faded-black: rgb(0 0 0 / 0.3);
	--color-faded-blue: rgb(97 141 180 / 0.3);
	--color-faded-purple: rgb(134 100 121 / 0.3);
	--color-faded-red: rgb(255 0 79 / 0.3);
	--color-faded-warm-grey: rgb(172 161 153 / 0.3);
	--color-faded-white: rgb(255 255 255 / 0.3);
	/*  */
	--color-transparent-black: rgb(0 0 0 / 0);
	--color-transparent-blue: rgb(97 141 180 / 0);
	--color-transparent-purple: rgb(134 100 121 / 0);
	--color-transparent-red: rgb(255 0 79 / 0);
	--color-transparent-warm-grey: rgb(172 161 153 / 0);
	--color-transparent-white: rgb(255 255 255 / 0);
	/*  */
	--ease-in-out: cubic-bezier(0.365, 0, 0.11, 1);
	--ease-in-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	/*  */
	--font-sans: "Roboto", "Helvetica Neue", sans-serif;
	--font-title: "LL Replica Bold Web", "Roboto", "Helvetica Neue", sans-serif;
	--font-weight-normal: 400;
	--font-weight-bold: 700;
}

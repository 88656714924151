.carousel {
	background-color: var(--color-grey);
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
	min-height: 50vw;
}

.carousel__slides-container {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.carousel__slide {
	background-color: var(--color-grey);
	color: white;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 240ms linear;
	width: 100%;
	height: 100%;
	z-index: 1;
}

a.carousel__slide {
	text-decoration: none;
}

.carousel__slide.is-active {
	opacity: 1;
	transition-delay: 64ms;
	z-index: 25; /* max items */
}

.carousel__slide__content {
	box-sizing: border-box;
	display: inline-block;
	position: absolute;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% - 2.5rem);
	max-height: 90%;
	z-index: 2;
}

.carousel__slide__title {
	display: inline-block;
	font-family: var(--font-title);
	font-size: 2.6875rem;
	font-weight: var(--font-weight-bold);
	letter-spacing: normal;
	line-height: 0.9;
	margin-bottom: 0.5rem;
	opacity: 0;
	position: relative;
	text-transform: uppercase;
	transform: translateY(25px);
	transition:
		opacity 240ms linear 128ms,
		transform 800ms cubic-bezier(0.635, 0, 0.025, 1);
	width: 100%;
	max-width: 250px;
}

@media (min-width: 375px) {
	.carousel__slide__title {
		max-width: 275px;
	}
}

@media (min-width: 568px) {
	.carousel__slide__title {
		font-size: 3.125rem;
		max-width: 350px;
	}
}

@media (min-width: 768px) {
	.carousel__slide__title {
		font-size: 5rem;
		margin-bottom: 1.125rem;
		max-width: 650px;
	}
}

@media (min-width: 1024px) {
	.carousel__slide__title {
		font-size: 4rem;
	}
}

@media (min-width: 1024px) and (max-width: 1279px) {
	.carousel__slide__title {
		max-width: 530px;
	}
}

@media (min-width: 1280px) {
	.carousel__slide__title {
		font-size: 5rem;
		max-width: 665px;
	}
}

@media (min-width: 1380px) {
	.carousel__slide__title {
		font-size: 6.5rem;
		max-width: 865px;
	}
}

@media (min-width: 1920px) {
	.carousel__slide__title {
		font-size: 8.625rem;
		max-width: 1150px;
	}
}

.carousel__slide.is-active .carousel__slide__title {
	opacity: 1;
	transform: translateY(0);
}

/*
	 * (1) The first slide always starts out as being set as active, but we
	 * we still want it to have a transition. We manually set it as
	 * "untransitioned" so it will animate as soon as the slideshow kicks in.
	 *
	 * If there's no JS, we don't do this
	 *
	 */

html:not(.no-js) .carousel.is-observing .carousel__slide.is-active:first-child:not(:last-of-type) .carousel__slide__title,
html:not(.no-js) .carousel:not(.is-resolved) .carousel__slide.is-active:first-child:not(:last-of-type) .carousel__slide__title {
	opacity: 0;
	transform: translateY(25px);
}

/*
	 * (2) Show the the slide as "transitioned" (fully visible)
	 *
	 * (2a) we need to animate in the first child on load, see (1)
	 * (2b) the slide is the only slide in the slideshow (will function as hero)
	 * (2c) a slide is set to active by navigating the slideshow
	 *
	 */

.carousel:not(.is-resolved) *.carousel__slide.is-active:last-of-type .carousel__slide__title,
.carousel.is-resolved:not(.is-observing) .carousel__slide.is-active:first-child .carousel__slide__title,
.carousel__slide:not(:first-child).is-active .carousel__slide__title {
	/* (2c) */
	opacity: 1;
	transform: translateY(0);
}

.carousel__slide__subtitle {
	display: inline-block;
	font-family: var(--font-title);
	font-size: 1.0625rem;
	font-weight: var(--font-weight-normal);
	letter-spacing: normal;
	line-height: 1.11;
	opacity: 0;
	position: relative;
	transform: translateY(10px);
	transition:
		opacity 272ms linear 432ms,
		transform 800ms cubic-bezier(0.635, 0, 0.025, 1) 208ms;
}

@media (min-width: 568px) {
	.carousel__slide__subtitle {
		font-size: 1.125rem;
	}
}

@media (min-width: 768px) {
	.carousel__slide__subtitle {
		font-size: 1.75rem;
		line-height: 1.07;
		max-width: 650px;
	}
}

@media (min-width: 1024px) {
	.carousel__slide__subtitle {
		font-size: 1.5rem;
	}
}

@media (min-width: 1380px) {
	.carousel__slide__subtitle {
		font-size: 2.25rem;
	}
}

/* (1) */

html:not(.no-js) .carousel.is-observing .carousel__slide.is-active:first-child:not(:last-of-type) .carousel__slide__subtitle,
html:not(.no-js) .carousel:not(.is-resolved) .carousel__slide.is-active:first-child:not(:last-of-type) .carousel__slide__subtitle {
	opacity: 0;
	transform: translateY(10px);
}

/* (2) */

.carousel:not(.is-resolved) *.carousel__slide.is-active:last-of-type .carousel__slide__subtitle,
.carousel.is-resolved:not(.is-observing) .carousel__slide.is-active:first-child .carousel__slide__subtitle,
.carousel__slide:not(:first-child).is-active .carousel__slide__subtitle {
	/* (2c) */
	opacity: 1;
	transform: translateY(0);
}

.carousel__slide__background {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	transform: scale(1);
	transition: transform 800ms cubic-bezier(0.5, 0, 0.015, 1);
	width: 100%;
	height: 100%;
	z-index: 1;
}

.carousel__slide__background::after {
	background-color: rgb(0 0 0 / 0.2);
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

/* (1) */

html:not(.no-js) .carousel.is-observing .carousel__slide.is-active:first-child:not(:last-of-type) .carousel__slide__background,
html:not(.no-js) .carousel:not(.is-resolved) .carousel__slide.is-active:first-child:not(:last-of-type) .carousel__slide__background {
	transform: scale(1);
}

/* (2) */

.carousel:not(.is-resolved) *.carousel__slide.is-active:last-of-type .carousel__slide__background,
.carousel.is-resolved:not(.is-observing) .carousel__slide.is-active:first-child .carousel__slide__background,
.carousel__slide:not(:first-child).is-active .carousel__slide__background {
	/* (2c) */
	transform: scale(1.02);
}

.carousel__slide__background__media {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	min-width: 100%;
	max-width: none;
	height: auto;
	min-height: 100%;
	z-index: 1;
}

@supports (object-fit: cover) {
	.carousel__slide__background__media {
		object-fit: cover;
		object-position: 50% 50%;
		width: 100%;
		min-width: 0;
		height: 100%;
		min-height: 0;
	}
}

.carousel__discover-label {
	color: white;
	display: inline-block;
	font-family: var(--font-title);
	font-size: 0.8125rem;
	font-weight: var(--font-weight-normal);
	letter-spacing: normal;
	line-height: normal;
	margin: 0;
	pointer-events: none;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	bottom: 1.25rem;
	left: 50%;
	transform: translateX(-50%);
	transition: color 96ms linear;
	z-index: 26;
}

/* max items + 1 */
@media (min-width: 768px) {
	.carousel__discover-label {
		font-size: 1.25rem;
		bottom: 2.5rem;
	}
}

.carousel__discover-label .icon-bracket-left,
.carousel__discover-label .icon-bracket-right {
	display: block;
	opacity: 0;
	position: absolute;
	top: 50%;
	transition:
		opacity 96ms linear,
		transform 128ms ease-in-out;
	width: 5px;
	height: 100%;
}

@media (max-width: 767px) {
	.carousel__discover-label .icon-bracket-left,
	.carousel__discover-label .icon-bracket-right {
		display: none;
	}
}

.carousel__discover-label .icon-bracket-left {
	left: 0;
	transform: translate(-200%, -50%) translateZ(0);
}

.carousel__discover-label .icon-bracket-right {
	right: 0;
	transform: translate(200%, -50%) translateZ(0);
}

a.carousel__slide:focus ~ .carousel__discover-label .icon-bracket-left,
a.carousel__slide:hover ~ .carousel__discover-label .icon-bracket-left,
a.carousel__slide:focus ~ .carousel__discover-label .icon-bracket-right,
a.carousel__slide:hover ~ .carousel__discover-label .icon-bracket-right {
	opacity: 1;
}

a.carousel__slide:focus ~ .carousel__discover-label .icon-bracket-left,
a.carousel__slide:hover ~ .carousel__discover-label .icon-bracket-left {
	transform: translate(-150%, -50%) translateZ(0);
}

a.carousel__slide:focus ~ .carousel__discover-label .icon-bracket-right,
a.carousel__slide:hover ~ .carousel__discover-label .icon-bracket-right {
	transform: translate(150%, -50%) translateZ(0);
}

.carousel__discover-label > span {
	display: inline-block;
}

.carousel__navigation {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	opacity: 0;
	padding: 0;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 1.125rem;
	left: 50%;
	transform: translateX(-50%);
	transition: opacity 96ms linear;
	width: auto;
	max-width: calc(100% - 8rem);
	z-index: 26;
}

/* max items + 1 */
@media (min-width: 375px) {
	.carousel__navigation {
		max-width: calc(100% - 12.5rem);
	}
}

@media (min-width: 768px) {
	.carousel__navigation {
		top: 1.625rem;
	}
}

@media (min-width: 1024px) {
	.carousel__navigation {
		max-width: calc(100% - 13rem);
	}
}

@media (min-width: 1380px) {
	.carousel__navigation {
		max-width: calc(100% - 15rem);
	}
}

@media (min-width: 1920px) {
	.carousel__navigation {
		max-width: calc(100% - 20rem);
	}
}

.carousel.is-resolved .carousel__navigation {
	opacity: 1;
}

.carousel__navigation__item {
	display: inline-block;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
}

@media (min-width: 768px) {
	.carousel__navigation__item {
		margin-right: 0.125rem;
		margin-left: 0.125rem;
	}
}

.carousel__navigation__button {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	margin: 0;
	padding: 0;
	position: relative;
	width: 1rem;
	height: 1rem;
}

.carousel__navigation__button::after {
	appearance: none;
	background: rgb(255 255 255 / 0);
	border: 1px solid white;
	border-radius: 100%;
	box-shadow: none;
	box-sizing: border-box;
	content: "";
	display: block;
	position: absolute;
	top: 0.125rem;
	left: 0.125rem;
	transition:
		background-color 96ms linear,
		border-color 96ms linear,
		color 96ms linear;
	width: 0.75rem;
	height: 0.75rem;
}

.carousel__navigation__button:focus {
	outline: 0;
}

.carousel__navigation__button:hover {
	cursor: pointer;
}

.carousel__navigation__button.is-active::after {
	background-color: white;
}

.column-text {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding: 1.25rem;
	align-items: flex-start;
	text-align: left;
	height: 100%;
	min-height: 87.5vw;
}

@media (min-width: 768px) {
	.column-text {
		padding: 2.25rem;
		min-height: 43.75vw;
	}
}

@media (min-width: 1380px) {
	.column-text {
		padding: 3.75rem;
	}
}

.column-text__text {
	font-family: var(--font-title);
	font-size: 2rem;
	font-weight: var(--font-weight-bold);
	line-height: normal;
	margin-bottom: 0.5rem;
}

@media (min-width: 1380px) {
	.column-text__text {
		font-size: 3.125rem;
		margin-bottom: 0.625rem;
	}
}

.column-text__links {
	margin-top: auto;
}

.story {
	display: block;
	position: relative;
}

.story__list {
	background-color: #ffffff;
	border-top: 1px solid var(--color-grey-medium);
	border-bottom: 1px solid var(--color-grey-medium);
	border-left: 1px solid var(--color-grey-medium);
	box-sizing: border-box;
	list-style: none;
	overflow: hidden;
	padding: 1rem;
	position: fixed;
	text-align: right;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	transition: max-width 320ms var(--ease-in-out-quad);
	max-width: 2.5rem;
	z-index: 3;
}

@media (max-width: 767px) {
	.story__list {
		display: none;
	}
}

.story__list:hover,
.story__list:focus {
	max-width: 100%;
}

.t-expro .story__list {
	border-top: 1px solid var(--color-faded-blue);
	border-bottom: 1px solid var(--color-faded-blue);
	border-left: 1px solid var(--color-faded-blue);
}

.t-express .story__list {
	border-top: 1px solid var(--color-faded-red);
	border-bottom: 1px solid var(--color-faded-red);
	border-left: 1px solid var(--color-faded-red);
}

.t-xplay .story__list {
	border-top: 1px solid var(--color-faded-warm-grey);
	border-bottom: 1px solid var(--color-faded-warm-grey);
	border-left: 1px solid var(--color-faded-warm-grey);
}

.t-xtrail .story__list {
	border-top: 1px solid var(--color-faded-purple);
	border-bottom: 1px solid var(--color-faded-purple);
	border-left: 1px solid var(--color-faded-purple);
}

.story__list__link {
	color: var(--color-grey-medium);
	display: block;
	position: relative;
	text-decoration: none;
	transition: color 96ms var(--ease-in-out-quad);
	white-space: nowrap;
}

.t-expro .story__list__link {
	color: var(--color-faded-blue);
}

.t-express .story__list__link {
	color: var(--color-faded-red);
}

.t-xplay .story__list__link {
	color: var(--color-faded-warm-grey);
}

.t-xtrail .story__list__link {
	color: var(--color-faded-purple);
}

.story__list__link::after {
	background-color: currentColor;
	border-radius: 50%;
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%) translateY(2px);
	width: 0.5rem;
	height: 0.5rem;
}

.story__list__link:hover,
.story__list__link:focus,
.story__list__link.is-active {
	color: var(--color-grey);
}

.t-expro .story__list__link:hover,
.t-expro .story__list__link:focus,
.t-expro .story__list__link.is-active {
	color: var(--color-blue);
}

.t-express .story__list__link:hover,
.t-express .story__list__link:focus,
.t-express .story__list__link.is-active {
	color: var(--color-red);
}

.t-xplay .story__list__link:hover,
.t-xplay .story__list__link:focus,
.t-xplay .story__list__link.is-active {
	color: var(--color-warm-grey);
}

.t-xtrail .story__list__link:hover,
.t-xtrail .story__list__link:focus,
.t-xtrail .story__list__link.is-active {
	color: var(--color-purple);
}

.story__list__label {
	box-sizing: border-box;
	display: inline-block;
	opacity: 0;
	padding-right: 1rem;
	transition: opacity 96ms var(--ease-in-out-quad);
}

.story__list:hover .story__list__label {
	opacity: 1;
}

.story__slide {
	border-bottom: 1px solid var(--color-grey);
	padding-top: 102px;
	padding-bottom: 40px;
}

@media (min-width: 768px) {
	.story__slide {
		padding-bottom: 110px;
	}
}

@media (min-width: 1024px) {
	.story__slide {
		padding-top: 130px;
		padding-bottom: 172px;
	}
}

.story__title {
	color: var(--color-grey);
}

.t-expro .story__title {
	color: var(--color-blue);
}

.t-express .story__title {
	color: var(--color-red);
}

.t-xplay .story__title {
	color: var(--color-warm-grey);
}

.t-xtrail .story__title {
	color: var(--color-purple);
}

.story__video,
.story__image {
	margin: 0 auto;
}

/* The styling and animations when JS is active */
@media (min-width: 768px) {
	.story.is-resolved .story__slide {
		box-sizing: border-box;
		position: relative;
		height: 100vh;
	}
}

@media (min-width: 768px) {
	.story.is-resolved .story__header,
	.story.is-resolved .story__footer {
		position: absolute;
		left: 0;
		width: 100%;
	}
}

@media (min-width: 768px) {
	.story.is-resolved .story__header {
		top: 80px;
		z-index: 2;
	}

	@supports (mix-blend-mode: darken) {
		.story.is-resolved .story__header {
			z-index: 0;
		}
	}
}

@media (min-width: 1024px) {
	.story.is-resolved .story__header {
		top: 100px;
	}
}

@media (min-width: 768px) {
	.story.is-resolved .story__footer {
		background-color: #ffffff;
		padding-bottom: 1rem;
		bottom: 0;
	}
}

@media (min-width: 1024px) {
	.story.is-resolved .story__footer {
		padding-bottom: 2rem;
	}
}

@media (min-width: 768px) {
	.story.is-resolved .story__media {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
	}
}

@media (min-width: 768px) {
	.story.is-resolved .story__video,
	.story.is-resolved .story__image {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: auto;
		max-width: none;
		height: 100%;
	}

	@supports (object-fit: contain) {
		.story.is-resolved .story__video,
		.story.is-resolved .story__image {
			object-fit: contain;
			position: static;
			top: auto;
			left: auto;
			transform: none;
			width: 100%;
		}
	}
}

@supports (mix-blend-mode: darken) {
	.story.is-resolved .story__video {
		mix-blend-mode: darken;
	}
}

@media (min-width: 768px) {
	.story.is-resolved .story__slide:hover .story__header {
		z-index: 2;
	}
}

.story__title__chunk,
.story__title__char {
	display: inline-flex;
}

@media (min-width: 768px) {
	.story.is-resolved .story__slide:not(.is-animating) .story__title__char {
		opacity: 0;
		transform: translateY(2rem) translateZ(0);
	}

	.story.is-resolved .story__slide:not(.is-animating) .story__text {
		opacity: 0;
		transform: scale3d(0.98, 0.98, 1);
	}
}

@media (min-width: 768px) {
	.story__slide.is-animating .story__title__char {
		opacity: 1;
		transform: translateZ(0);
		transition:
			transform 192ms var(--ease-in-out-quad),
			opacity 360ms var(--ease-in-out);
	}

	.story__slide.is-animating .story__title__char:nth-child(1) {
		transition-delay: 400ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(2) {
		transition-delay: 480ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(3) {
		transition-delay: 560ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(4) {
		transition-delay: 640ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(5) {
		transition-delay: 720ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(6) {
		transition-delay: 800ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(7) {
		transition-delay: 880ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(8) {
		transition-delay: 960ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(9) {
		transition-delay: 1040ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(10) {
		transition-delay: 1120ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(11) {
		transition-delay: 1200ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(12) {
		transition-delay: 1280ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(13) {
		transition-delay: 1360ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(14) {
		transition-delay: 1440ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(15) {
		transition-delay: 1520ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(16) {
		transition-delay: 1600ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(17) {
		transition-delay: 1680ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(18) {
		transition-delay: 1760ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(19) {
		transition-delay: 1840ms;
	}

	.story__slide.is-animating .story__title__char:nth-child(20) {
		transition-delay: 1920ms;
	}

	.story__slide.is-animating .story__text {
		opacity: 1;
		transform: translateZ(0);
		transition:
			transform 192ms var(--ease-in-out-quad),
			opacity 228ms var(--ease-in-out);
	}

	.story__slide.is-animating .story__text {
		transition-delay: 1200ms;
	}
}

@media (min-width: 768px) {
	.story__footer {
		padding-top: 1.25rem;
	}
}

@media (min-width: 1024px) {
	.story__footer {
		padding-top: 2.5rem;
	}
}

@media (min-width: 768px) {
	.story__footer::before {
		background: linear-gradient(to bottom, rgb(255 255 255 / 0) 0%, rgb(255 255 255 / 1) 100%);
		content: "";
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(0, -100%);
		width: 100%;
		height: 200px;
	}
}

@media (min-width: 1024px) {
	.story__footer::before {
		height: 300px;
	}
}

@media (min-width: 768px) {
	.story__footer-toggle:checked + .story__footer::before {
		display: block;
	}
}

.story__text {
	text-align: center;
}

.story__text__preview {
	display: none;
	margin: 0 auto;
	text-align: left;
	max-width: 720px;
	height: 1.9rem;
}

@media (min-width: 768px) {
	.story__text__preview {
		display: block;
	}
}

.story__text__preview > p:first-child {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
	height: 1.9rem;
}

.story__text__preview > p:not(:first-child) {
	display: none;
}

.t-expro .story__text__preview {
	color: var(--color-blue);
}

.t-express .story__text__preview {
	color: var(--color-red);
}

.t-xplay .story__text__preview {
	color: var(--color-warm-grey);
}

.t-xtrail .story__text__preview {
	color: var(--color-purple);
}

.story__footer-toggle:checked + .story__footer .story__text__preview {
	display: none;
}

.story__text__full {
	display: block;
	margin: 0 auto;
	text-align: left;
	max-width: 900px;
}

@media (min-width: 768px) {
	.story__text__full {
		display: none;
	}
}

@media (min-width: 768px) {
	.story__footer-toggle:checked + .story__footer .story__text__full {
		display: block;
	}
}

.story__text__toggle {
	display: none;
}

@media (min-width: 768px) {
	.story__text__toggle {
		display: inline-block;
		margin-top: 1.25rem;
	}
}

@media (min-width: 1024px) {
	.story__text__toggle {
		margin-top: 2.5rem;
	}
}

.t-expro .story__text__toggle {
	color: var(--color-blue);
}

.t-express .story__text__toggle {
	color: var(--color-red);
}

.t-xplay .story__text__toggle {
	color: var(--color-warm-grey);
}

.t-xtrail .story__text__toggle {
	color: var(--color-purple);
}

.story__text__toggle.story__text__toggle--close {
	display: none;
}

.story__footer-toggle:checked + .story__footer .story__text__toggle.story__text__toggle--open {
	display: none;
}

@media (min-width: 768px) {
	.story__footer-toggle:checked + .story__footer .story__text__toggle.story__text__toggle--close {
		display: inline-block;
	}
}

.story__text__toggle.story__text__toggle--open {
	position: relative;
}

/* Pulsing dot */

.story__text__toggle.story__text__toggle--open::after {
	animation: PULSE_BLACK 2000ms infinite;
	background: black;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgb(0 0 0 / 0.3);
	content: "";
	display: block;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 55%;
	right: 0;
	transform: translate(250%, -50%);
	width: 1.125rem;
	height: 1.125rem;
}

@media (min-width: 768px) {
	.story__text__toggle.story__text__toggle--open::after {
		width: 0.9375rem;
		height: 0.9375rem;
	}
}

.t-expro .story__text__toggle.story__text__toggle--open::after {
	animation: PULSE_BLUE 2000ms infinite;
	background-color: var(--color-blue);
	box-shadow: 0 0 0 0 var(--color-faded-blue);
}

.t-express .story__text__toggle.story__text__toggle--open::after {
	animation: PULSE_RED 2000ms infinite;
	background-color: var(--color-red);
	box-shadow: 0 0 0 0 var(--color-faded-red);
}

.t-xplay .story__text__toggle.story__text__toggle--open::after {
	animation: PULSE_WARM_GREY 2000ms infinite;
	background-color: var(--color-warm-grey);
	box-shadow: 0 0 0 0 var(--color-faded-warm-grey);
}

.t-xtrail .story__text__toggle.story__text__toggle--open::after {
	animation: PULSE_PURPLE 2000ms infinite;
	background-color: var(--color-purple);
	box-shadow: 0 0 0 0 var(--color-faded-purple);
}

.is-showing-hint .story__text__toggle.story__text__toggle--open::after {
	opacity: 1;
}

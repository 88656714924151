.embed {
	position: relative;
}

.embed::before {
	content: "";
	display: block;
	padding-bottom: 56.25%;
}

.embed[data-aspect-ratio="1:1"]::before {
	padding-bottom: 100%;
}

.embed[data-aspect-ratio="1:2"]::before {
	padding-bottom: 200%;
}

.embed[data-aspect-ratio="2:1"]::before {
	padding-bottom: 50%;
}

.embed[data-aspect-ratio="3:4"]::before {
	padding-bottom: 133.3333%;
}

.embed[data-aspect-ratio="4:3"]::before {
	padding-bottom: 75%;
}

.embed[data-aspect-ratio="21:9"]::before {
	padding-bottom: 42.8571%;
}

.embed__iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

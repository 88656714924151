.vertical-navigation {
	list-style: none;
	margin: 0;
	padding: 0;
}

.vertical-navigation__link {
	color: currentColor;
	font-size: 0.875rem;
}

.vertical-navigation--large .vertical-navigation__link {
	font-size: 1.625rem;
	line-height: 1.33;
	text-decoration: none;
}

.vertical-navigation--large .vertical-navigation__link:not(.is-active) {
	opacity: 0.3;
}

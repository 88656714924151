@keyframes NEWSLETTER-ANIMATE-IN-ICON {
	0% {
		opacity: 0;
		transform: translateY(-3rem) translateZ(0);
	}

	100% {
		opacity: 1;
		transform: translateZ(0);
	}
}

.newsreel-letter-grid {
	color: black;
}

.newsreel-letter-grid__row {
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.newsreel-letter-grid__row:nth-child(1n + 2) {
	/* calc: width of wrapper divided by number of letter columns (1) */
	padding-left: calc(80vw / 6);
}

@media (min-width: 768px) {
	.newsreel-letter-grid__row:nth-child(1n + 2) {
		/* half of wrapper, minus gap, divided by number of columns (2) */
		padding-left: calc(((75vw - 5rem) / 2) / 8);
	}
}

@media (min-width: 1680px) {
	.newsreel-letter-grid__row:nth-child(1n + 2) {
		/* (2) */
		padding-left: calc(((1260px - 5rem) / 2) / 8);
	}
}

.newsreel-letter-grid__letter {
	color: currentColor;
	display: flex;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	width: calc(80vw / 6);
	height: calc(80vw / 6); /* (1) */
}

/* (1) */
@media (min-width: 768px) {
	.newsreel-letter-grid__letter {
		width: calc(((75vw - 5rem) / 2) / 8); /* (2) */
		height: calc(((75vw - 5rem) / 2) / 6); /* (2) */
	}
}

@media (min-width: 1680px) {
	.newsreel-letter-grid__letter {
		width: calc(((1260px - 5rem) / 2) / 8); /* (2) */
		height: calc(((1260px - 5rem) / 2) / 6); /* (2) */
	}
}

.newsreel-letter-grid__letter.newsreel-letter-grid__letter--W {
	/* W is 1.3 times wider then other characters */
	width: calc((80vw / 6) * 1.3);
}

/* (1) */
@media (min-width: 768px) {
	.newsreel-letter-grid__letter.newsreel-letter-grid__letter--W {
		width: calc((((75vw - 5rem) / 2) / 8) * 1.3); /* (2) */
	}
}

@media (min-width: 1680px) {
	.newsreel-letter-grid__letter.newsreel-letter-grid__letter--W {
		width: calc((((1260px - 5rem) / 2) / 8) * 1.3); /* (2) */
	}
}

.newsreel-letter-grid__letter .icon {
	animation: NEWSLETTER-ANIMATE-IN-ICON 400ms var(--ease-in-out-quad) 1440ms forwards;
	display: block;
	fill: currentColor;
	opacity: 0;
	transform: translateY(-3rem) translateZ(0);
	width: 100%;
	height: 100%;
}

.newsreel-letter-grid__letter.newsreel-letter-grid__letter--1 .icon {
	animation-delay: 128ms;
}

.newsreel-letter-grid__letter.newsreel-letter-grid__letter--2 .icon {
	animation-delay: 256ms;
}

.newsreel-letter-grid__letter.newsreel-letter-grid__letter--3 .icon {
	animation-delay: 384ms;
}

.newsreel-letter-grid__letter.newsreel-letter-grid__letter--4 .icon {
	animation-delay: 512ms;
}

.newsreel-letter-grid__letter.newsreel-letter-grid__letter--5 .icon {
	animation-delay: 640ms;
}

.newsreel-letter-grid__letter.newsreel-letter-grid__letter--6 .icon {
	animation-delay: 768ms;
}

.newsreel-letter-grid__letter.newsreel-letter-grid__letter--7 .icon {
	animation-delay: 896ms;
}

.newsreel-letter-grid__letter.newsreel-letter-grid__letter--8 .icon {
	animation-delay: 1024ms;
}

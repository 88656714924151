body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--font-sans);
	font-size: 0.9375rem;
	font-weight: var(--font-weight-normal);
	line-height: 1.6154;
}

@media (min-width: 768px) {
	body {
		font-size: 1rem;
		line-height: 1.5;
	}
}

@media (min-width: 1024px) {
	body {
		font-size: 1.25rem;
	}
}

*::selection {
	background-color: black;
	color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--font-title);
	font-weight: var(--font-weight-bold);
	margin-bottom: 1.25rem;
}

@media (min-width: 1024px) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
	margin-bottom: 0;
}

h1:only-child,
h2:only-child,
h3:only-child,
h4:only-child,
h5:only-child,
h6:only-child {
	margin-top: 0;
	margin-bottom: 0;
}

h1,
.wysiwyg-content h2 {
	font-size: 1.125rem;
	line-height: 1.2222;
}

@media (min-width: 768px) {
	h1,
	.wysiwyg-content h2 {
		font-size: 1.75rem;
		line-height: 1.25;
	}
}

@media (min-width: 1280px) {
	h1,
	.wysiwyg-content h2 {
		font-size: 2.25rem;
	}
}

h2,
.wysiwyg-content h3 {
	font-size: 0.875rem;
	line-height: 1.5833;
	text-transform: uppercase;
}

@media (min-width: 1024px) {
	h2,
	.wysiwyg-content h3 {
		font-size: 1.25rem;
	}
}

@media (min-width: 1680px) {
	h2,
	.wysiwyg-content h3 {
		font-size: 1.5rem;
	}
}

.wysiwyg-content h2 {
	text-transform: none;
}

h3,
h4,
h5,
h6 {
	font-size: 0.75rem;
	letter-spacing: 1px;
	line-height: 1.5833;
	text-transform: uppercase;
}

@media (min-width: 1024px) {
	h3,
	h4,
	h5,
	h6 {
		font-size: 0.875rem;
	}
}

p {
	margin: 0 0 1rem 0;
}

@media (min-width: 1024px) {
	p {
		margin-bottom: 2rem;
	}
}

p:only-child:not([class]),
p:last-child:not([class]) {
	margin-bottom: 0;
}

a:not([class]),
a[class=""] {
	color: currentColor;
	text-decoration: underline;
	transition: opacity 96ms linear;
}

a:not([class]):hover,
a[class=""]:hover {
	opacity: 0.6;
}

b,
strong {
	font-weight: var(--font-weight-bold);
}

.type-a {
	font-family: var(--font-title);
	font-size: 2rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.02;
}

@media (min-width: 568px) {
	.type-a {
		font-size: 3rem;
	}
}

@media (min-width: 768px) {
	.type-a {
		font-size: 4rem;
	}
}

@media (min-width: 1024px) {
	.type-a {
		font-size: 5.5rem;
	}
}

@media (min-width: 1380px) {
	.type-a {
		font-size: 7.5rem;
	}
}

@media (min-width: 1680px) {
	.type-a {
		font-size: 9.375rem;
	}
}

.type-b {
	font-family: var(--font-title);
	font-size: 1.5rem;
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.05rem;
	line-height: 0.95;
}

@media (min-width: 568px) {
	.type-b {
		font-size: 1.875rem;
	}
}

@media (min-width: 768px) {
	.type-b {
		font-size: 2rem;
	}
}

@media (min-width: 1024px) {
	.type-b {
		font-size: 1.5rem;
	}
}

@media (min-width: 1380px) {
	.type-b {
		font-size: 2rem;
	}
}

@media (min-width: 1680px) {
	.type-b {
		font-size: 3rem;
	}
}

.type-c {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--font-sans);
	font-size: 0.9375rem;
	font-weight: var(--font-weight-normal);
	line-height: 1.6;
}

@media (min-width: 768px) {
	.type-c {
		font-size: 1rem;
		line-height: 1.5;
	}
}

@media (min-width: 1024px) {
	.type-c {
		font-size: 1.125rem;
	}
}

blockquote {
	color: black;
	font-family: var(--font-title);
	font-size: 1.125rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.22;
	margin: 1.25rem 0;
	padding: 0;
	width: 100%;
}

@media (min-width: 1024px) {
	blockquote {
		font-size: 1.5625rem;
		line-height: 1.28;
		margin-top: 3.125rem;
		margin-bottom: 3.125rem;
	}
}

blockquote p:first-child:not(:only-child) {
	margin-bottom: 0.625rem;
}

blockquote p:not(:first-child) {
	font-size: 0.75rem;
	line-height: normal;
	text-transform: uppercase;
}

@media (min-width: 1024px) {
	blockquote p:not(:first-child) {
		font-size: 0.875rem;
	}
}

blockquote p:not(:first-child)::after,
blockquote p:not(:first-child)::before {
	background-repeat: no-repeat;
	background-size: contain;
	content: "";
	display: inline-block;
	transform: translateY(2px);
	width: 7px;
	height: 14px;
}

@media (min-width: 1024px) {
	blockquote p:not(:first-child)::after,
	blockquote p:not(:first-child)::before {
		transform: translateY(3px);
		width: 8px;
		height: 16px;
	}
}

blockquote p:not(:first-child)::after {
	background-image: url("../images/bracket-right.png");
	background-position: center right;
}

blockquote p:not(:first-child)::before {
	background-image: url("../images/bracket-left.png");
	background-position: center left;
}

/*
 * List of links that'll be separated with a | (pipe)
 */

.separated-link-list {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	transition: opacity 160ms 96ms linear;
}

.separated-link-list__item {
	box-sizing: border-box;
}

@media (max-width: 767px) {
	.separated-link-list__item {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.separated-link-list__item:nth-child(2n + 1) {
	padding-right: 12.5%;
	position: relative;
}

@media (min-width: 768px) {
	.separated-link-list__item:nth-child(2n + 1) {
		padding-right: 3rem;
	}
}

.separated-link-list__item:nth-child(2n + 1):not(:last-child)::after {
	content: "|";
	position: absolute;
	top: 0;
	right: 0;
}

.separated-link-list__item:nth-child(2n + 2) {
	padding-left: 12.5%;
}

@media (min-width: 768px) {
	.separated-link-list__item:nth-child(2n + 2) {
		padding-left: 3rem;
	}
}

.separated-link-list__link {
	color: currentColor;
	font-size: 0.75rem;
	font-weight: var(--font-weight-bold);
	letter-spacing: 1px;
	line-height: 1.5833;
	opacity: 1;
	text-decoration: none;
	text-transform: uppercase;
	transition: opacity 96ms linear;
}

@media (min-width: 1024px) {
	.separated-link-list__link {
		font-size: 0.875rem;
	}
}

.separated-link-list__link:hover {
	opacity: 0.6;
}

@media (min-width: 768px) {
	.side-to-side-media {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

@media (min-width: 768px) {
	.side-to-side-media__left,
	.side-to-side-media__right {
		flex-basis: calc(50% - 0.5625rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (min-width: 1380px) {
	.side-to-side-media__left,
	.side-to-side-media__right {
		flex-basis: calc(50% - 0.625rem);
	}
}

.page-container {
	display: block;
	min-height: 100vh;
}

.page-container.has-no-hero {
	padding-top: 1.25rem;
}

.page-container.is-single-news-post {
	/* Make sure that content starts after site-header */
	padding-top: 3.125rem;
}

@media (min-width: 1024px) {
	.page-container.is-single-news-post {
		padding-top: 4rem;
	}
}

.page-container.is-landing-page {
	padding-top: 3.375rem;
}

@media (min-width: 768px) {
	.page-container.is-landing-page {
		padding-top: 4.3125rem;
	}
}

.page-container.is-story-page {
	padding-top: 0;
}

.page-container--no-overflow {
	overflow: hidden;
}

.news-post-hero {
	box-sizing: border-box;
	width: 100%;
}

.news-post-hero:last-child {
	margin-bottom: 1.25rem;
}

@media (min-width: 1024px) {
	.news-post-hero:last-child {
		margin-bottom: 3.125rem;
	}
}

.news-post-hero__content {
	border-bottom: 1px solid black;
	margin-bottom: 0.625rem;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	text-align: center;
}

@media (min-width: 1024px) {
	.news-post-hero__content {
		padding-top: 3.125rem;
		padding-bottom: 3.125rem;
	}
}

.news-post-hero--has-thumbnail .news-post-hero__content {
	border-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
	position: relative;
}

@media (max-width: 767px) {
	.news-post-hero--has-thumbnail .news-post-hero__content {
		min-height: 75vh;
	}
}

@media (min-width: 768px) {
	.news-post-hero--has-thumbnail .news-post-hero__content {
		padding-bottom: 64%;
	}
}

@media (min-width: 1680px) {
	.news-post-hero--has-thumbnail .news-post-hero__content {
		padding-bottom: 52%;
	}
}

.news-post-hero--has-thumbnail .news-post-hero__content::after {
	background-color: rgb(0 0 0 / 0.2);
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.news-post-hero__thumbnail {
	display: none;
}

.news-post-hero--has-thumbnail .news-post-hero__thumbnail {
	display: block;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.news-post-hero__title {
	font-family: var(--font-title);
	font-size: 1.875rem;
	font-weight: var(--font-weight-bold);
	line-height: normal;
	margin-top: 0;
	margin-bottom: 0;
	text-align: center;
}

@media (min-width: 1024px) {
	.news-post-hero__title {
		display: inline-block;
		font-size: 3.125rem;
		line-height: 1.1;
		margin-right: auto;
		margin-left: auto;
		max-width: 530px;
	}
}

.news-post-hero--has-thumbnail .news-post-hero__title {
	color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 3;
}

.news-post-hero__meta {
	color: var(--color-grey);
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.news-post-hero--has-thumbnail .news-post-hero__meta {
	box-sizing: border-box;
}

@media (max-width: 1023px) {
	.news-post-hero--has-thumbnail .news-post-hero__meta {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}

@media (min-width: 1024px) and (max-width: 1100px) {
	.news-post-hero--has-thumbnail .news-post-hero__meta {
		padding-right: 3.5rem;
		padding-left: 3.5rem;
	}
}

.news-post-hero__meta__item:nth-child(2):not(:last-child) {
	text-align: center;
}

.news-post-hero__meta__item:last-child {
	overflow: hidden;
	text-align: right;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 33%;
}

.back-link {
	color: currentColor;
	display: flex;
	overflow: visible;
	align-items: center;
	text-decoration: none;
	width: auto;
	width: max-content;
}

.back-link__arrow {
	display: inline-block;
	font-size: 0.75rem;
	margin-right: 0.5625rem;
	transition: transform 256ms ease-out;
}

@media (max-width: 767px) {
	.back-link__arrow {
		transform: translateY(1px);
	}
}

@media (min-width: 768px) {
	.back-link__arrow {
		font-size: 0.875rem;
	}
}

@media (max-width: 767px) {
	.back-link:focus .back-link__arrow,
	.back-link:hover .back-link__arrow {
		transform: translate(-5px, 1px);
	}
}

@media (min-width: 768px) {
	.back-link:focus .back-link__arrow,
	.back-link:hover .back-link__arrow {
		transform: translateX(-5px);
	}
}

.column-media {
	overflow: hidden;
	position: relative;
	height: 100%;
	min-height: 87.5vw;
}

@media (min-width: 768px) {
	.column-media {
		min-height: 43.75vw;
	}
}

.column-media__content {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding: 1.25rem;
	align-items: center;
	position: relative;
	text-align: center;
	height: 100%;
	min-height: 87.5vw;
	z-index: 2;
}

@media (min-width: 768px) {
	.column-media__content {
		padding: 2.25rem;
		min-height: 43.75vw;
	}
}

@media (min-width: 1380px) {
	.column-media__content {
		padding: 3.75rem;
	}
}

.column-media__title {
	font-family: var(--font-title);
	font-size: 2rem;
	font-weight: var(--font-weight-bold);
	line-height: normal;
	margin-bottom: 0.5rem;
	max-width: 420px;
}

@media (min-width: 1380px) {
	.column-media__title {
		font-size: 3.125rem;
		margin-bottom: 0.625rem;
	}
}

.column-media__subtitle {
	font-family: var(--font-title);
	font-size: 1.125rem;
	font-weight: var(--font-weight-normal);
	margin-top: 0;
	margin-bottom: 0.5rem;
	text-transform: none;
	max-width: 420px;
}

@media (min-width: 768px) {
	.column-media__subtitle {
		font-size: 1.25rem;
		margin-bottom: 0.625rem;
	}
}

.column-media__links {
	margin-top: auto;
}

.column-media__image {
	/* no object fit here, imgix takes care of that ✌🏻 */
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	z-index: 1;
}

.text-video-side-by-side {
	overflow: hidden;
}

@media (min-width: 768px) {
	.text-video-side-by-side {
		display: flex;
		align-items: center;
	}
}

/* Content. */
.text-video-side-by-side__content {
	box-sizing: border-box;
	padding: 1.25rem;
}

@media (max-width: 767px) {
	.text-video-side-by-side__content {
		text-align: center;
		width: 100%;
	}
}

@media (min-width: 768px) {
	.text-video-side-by-side__content {
		flex-basis: 0;
		flex-grow: 1;
		padding: 5.5rem;
	}
}

@media (min-width: 1380px) {
	.text-video-side-by-side__content {
		padding: 9rem;
	}
}

/* Title. */
.text-video-side-by-side__title {
	font-family: var(--font-title);
	font-size: 3.125rem;
	font-weight: var(--font-weight-bold);
	line-height: normal;
	margin-bottom: 1.25rem;
	opacity: 0;
	transform: translateY(25px);
	transition:
		opacity 240ms linear 128ms,
		transform 800ms cubic-bezier(0.635, 0, 0.025, 1);
}

@media (min-width: 768px) {
	.text-video-side-by-side__title {
		font-size: 3.75rem;
		margin-bottom: 1rem;
	}
}

@media (min-width: 1380px) {
	.text-video-side-by-side__title {
		font-size: 5.625rem;
		margin-bottom: 0;
	}
}

html.no-js .text-video-side-by-side__title,
.text-video-side-by-side.is-intersecting .text-video-side-by-side__title {
	opacity: 1;
	transform: translateZ(0);
}

/* Text. */
.text-video-side-by-side__text {
	font-family: var(--font-title);
	font-size: 1.25rem;
	font-weight: var(--font-weight-bold);
	line-height: normal;
	margin-bottom: 1.25rem;
	opacity: 0;
	transform: translateY(10px);
	transition:
		opacity 272ms linear 432ms,
		transform 800ms cubic-bezier(0.635, 0, 0.025, 1) 208ms;
}

@media (max-width: 767px) {
	.text-video-side-by-side__text {
		margin-right: auto;
		margin-left: auto;
		max-width: 280px;
	}
}

@media (min-width: 768px) {
	.text-video-side-by-side__text {
		font-size: 1.5rem;
	}
}

@media (min-width: 1380px) {
	.text-video-side-by-side__text {
		font-size: 1.875rem;
		margin-bottom: 1.875rem;
	}
}

html.no-js .text-video-side-by-side__text,
.text-video-side-by-side.is-intersecting .text-video-side-by-side__text {
	opacity: 1;
	transform: translateZ(0);
}

/* Links. */
.text-video-side-by-side__links {
	opacity: 0;
	transform: translateY(5px);
	transition:
		opacity 272ms linear 544ms,
		transform 800ms cubic-bezier(0.635, 0, 0.025, 1) 416ms;
}

html.no-js .text-video-side-by-side__links,
.text-video-side-by-side.is-intersecting .text-video-side-by-side__links {
	opacity: 1;
	transform: translateZ(0);
}

/* Video Container. */
.text-video-side-by-side__video-container {
	padding-bottom: calc(100% / 16 * 9); /* Defaults to 16:9. */
	position: relative;
	width: 100%;
}

.text-video-side-by-side__video-container[data-aspect-ratio="1:1"] {
	padding-bottom: calc(100% / 1 * 1);
}

.text-video-side-by-side__video-container[data-aspect-ratio="1:2"] {
	padding-bottom: calc(100% / 1 * 2);
}

.text-video-side-by-side__video-container[data-aspect-ratio="2:1"] {
	padding-bottom: calc(100% / 2 * 1);
}

.text-video-side-by-side__video-container[data-aspect-ratio="3:4"] {
	padding-bottom: calc(100% / 3 * 4);
}

.text-video-side-by-side__video-container[data-aspect-ratio="4:3"] {
	padding-bottom: calc(100% / 4 * 3);
}

.text-video-side-by-side__video-container[data-aspect-ratio="16:9"] {
	padding-bottom: calc(100% / 16 * 9);
}

.text-video-side-by-side__video-container[data-aspect-ratio="21:9"] {
	padding-bottom: calc(100% / 21 * 9);
}

@media (min-width: 768px) {
	.text-video-side-by-side__video-container {
		flex-basis: 50%;
		flex-shrink: 0;
		padding-bottom: calc((100% / 16 * 9) / 2);
	}

	.text-video-side-by-side__video-container[data-aspect-ratio="1:1"] {
		padding-bottom: calc((100% / 1 * 1) / 2);
	}

	.text-video-side-by-side__video-container[data-aspect-ratio="1:2"] {
		padding-bottom: calc((100% / 1 * 2) / 2);
	}

	.text-video-side-by-side__video-container[data-aspect-ratio="2:1"] {
		padding-bottom: calc((100% / 2 * 1) / 2);
	}

	.text-video-side-by-side__video-container[data-aspect-ratio="3:4"] {
		padding-bottom: calc((100% / 3 * 4) / 2);
	}

	.text-video-side-by-side__video-container[data-aspect-ratio="4:3"] {
		padding-bottom: calc((100% / 4 * 3) / 2);
	}

	.text-video-side-by-side__video-container[data-aspect-ratio="16:9"] {
		padding-bottom: calc((100% / 16 * 9) / 2);
	}

	.text-video-side-by-side__video-container[data-aspect-ratio="21:9"] {
		padding-bottom: calc((100% / 21 * 9) / 2);
	}
}

/* Video. */
.text-video-side-by-side__video {
	opacity: 0;
	transform: translateX(30px);
	transition:
		opacity 272ms linear 544ms,
		transform 800ms cubic-bezier(0.635, 0, 0.025, 1) 416ms;
}

html.no-js .text-video-side-by-side__video,
.text-video-side-by-side.is-intersecting .text-video-side-by-side__video {
	opacity: 1;
	transform: translateZ(0);
}

.media-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.media-grid__item {
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 1;
	margin-bottom: 0.375rem;
	overflow: hidden;
}

@media (min-width: 768px) {
	.media-grid__item {
		margin-bottom: 1rem;
	}
}

@media (min-width: 1024px) {
	.media-grid__item {
		margin-bottom: 1.75rem;
	}
}

.media-grid__item:nth-child(3n + 2),
.media-grid__item:nth-child(3n + 3) {
	flex-basis: calc(50% - 0.1875rem);
}

@media (min-width: 768px) {
	.media-grid__item:nth-child(3n + 2),
	.media-grid__item:nth-child(3n + 3) {
		flex-basis: calc(50% - 0.4375rem);
	}
}

@media (min-width: 1024px) {
	.media-grid__item:nth-child(3n + 2),
	.media-grid__item:nth-child(3n + 3) {
		flex-basis: calc(50% - 0.6875rem);
	}
}

.media-grid__item__media {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.media-grid__item:nth-child(3n + 2) .media-grid__item__media,
.media-grid__item:nth-child(3n + 3) .media-grid__item__media {
	padding-bottom: 66.6666%;
}

.media-grid__image,
.media-grid__video {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	min-width: 100%;
	max-width: none;
	height: auto;
	min-height: 100%;
}

@supports (object-fit: cover) {
	.media-grid__image,
	.media-grid__video {
		object-fit: cover;
		object-position: 50% 50%;
		width: 100%;
		height: 100%;
	}
}

/*
 * List of links that'll follow the current format theme colors
 */

.colored-link-list {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
}

.colored-link-list__link {
	border-bottom-color: var(--color-transparent-black);
	border-bottom-style: solid;
	border-bottom-width: 1px;
	color: var(--color-grey);
	font-size: 0.75rem;
	font-weight: var(--font-weight-bold);
	letter-spacing: 1px;
	line-height: 1.5833;
	padding-bottom: 2px;
	text-decoration: none;
	text-transform: uppercase;
	transition: border-bottom-color 96ms linear;
}

@media (min-width: 1024px) {
	.colored-link-list__link {
		font-size: 0.875rem;
	}
}

.t-expro .colored-link-list__link {
	border-bottom-color: var(--color-transparent-blue);
	color: var(--color-blue);
}

.t-express .colored-link-list__link {
	border-bottom-color: var(--color-transparent-red);
	color: var(--color-red);
}

.t-xplay .colored-link-list__link {
	border-bottom-color: var(--color-transparent-warm-grey);
	color: var(--color-warm-grey);
}

.t-xtrail .colored-link-list__link {
	border-bottom-color: var(--color-transparent-purple);
	color: var(--color-purple);
}

.colored-link-list__link:hover {
	border-bottom-color: var(--color-faded-black);
}

.t-expro .colored-link-list__link:hover {
	border-bottom-color: var(--color-faded-blue);
}

.t-express .colored-link-list__link:hover {
	border-bottom-color: var(--color-faded-red);
}

.t-xplay .colored-link-list__link:hover {
	border-bottom-color: var(--color-faded-warm-grey);
}

.t-xtrail .colored-link-list__link:hover {
	border-bottom-color: var(--color-faded-purple);
}

.banner {
	background-color: var(--color-grey);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	color: white;
	overflow: hidden;
	position: relative;
}

.banner.banner--light {
	background-color: var(--color-grey-light);
	color: black;
}

.banner__top {
	padding-top: 4rem;
	padding-bottom: 6rem;
	position: relative;
	z-index: 4;
}

@media (min-width: 768px) {
	.banner__top {
		padding-top: 6rem;
		padding-bottom: 8rem;
	}
}

@media (min-width: 1024px) {
	.banner__top {
		padding-top: 8rem;
		padding-bottom: 12rem;
	}
}

@media (min-width: 1380px) {
	.banner__top {
		padding-top: 10rem;
		padding-bottom: 15rem;
	}
}

@media (min-width: 768px) {
	.banner__wrapper {
		display: flex;
	}
}

.banner__title {
	text-shadow: 0 0 10px rgb(0 0 0 / 0.4);
}

.banner--light .banner__title {
	text-shadow: 0 0 10px rgb(255 255 255 / 0.2);
}

@media (max-width: 767px) {
	.banner__title {
		margin-bottom: 5rem;
	}
}

@media (min-width: 768px) {
	.banner__title {
		box-sizing: border-box;
		flex-basis: 61.5%;
		flex-grow: 0;
		flex-shrink: 0;
		padding-right: 10%;
	}
}

@media (min-width: 1024px) {
	.banner__title {
		padding-right: 15%;
	}
}

.banner__content {
	text-shadow: 0 0 10px rgb(0 0 0 / 0.4);
}

.banner--light .banner__content {
	text-shadow: 0 0 10px rgb(255 255 255 / 0.2);
}

@media (min-width: 768px) {
	.banner__content {
		flex-basis: 38.5%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.banner__background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.banner__background__image {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	min-width: 100%;
	max-width: none;
	height: auto;
	min-height: 100%;
}

@supports (object-fit: cover) {
	.banner__background__image {
		object-fit: cover;
		object-position: 50% 50%;
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		width: 100%;
		height: 100%;
	}
}

.point-of-view {
	box-sizing: border-box;
	overflow: hidden;
	padding-top: 4rem;
	padding-bottom: 0;
	position: relative;
}

@media (min-width: 768px) {
	.point-of-view {
		/* padding-bottom: 4rem; */
		padding-top: 6rem;
	}
}

@media (min-width: 1024px) {
	.point-of-view {
		/* padding-bottom: 5.5rem; */
		padding-top: 8rem;
	}
}

@media (min-width: 1380px) {
	.point-of-view {
		/* padding-bottom: 6.875rem; */
		padding-top: 10rem;
	}
}

.point-of-view__background {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.point-of-view__background__image {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
	max-width: none;
	height: auto;
}

.point-of-view__section {
	position: relative;
	z-index: 2;
}

.point-of-view__title {
	font-family: var(--font-title);
	font-size: 0.75rem;
	font-weight: var(--font-weight-bold);
	letter-spacing: 1px;
	line-height: 1.5833;
	margin-bottom: 2rem;
	text-transform: uppercase;
}

@media (min-width: 1024px) {
	.point-of-view__title {
		font-size: 0.875rem;
		margin-bottom: 1rem;
	}
}

@media (min-width: 1380px) {
	.point-of-view__title {
		margin-bottom: 0;
	}
}

.point-of-view__gallery {
	width: 100%;
}

/* i 4rem
s 4rem
m 5.5rem
w 6.875rem
*/

.mobile-navigation {
	background-color: var(--color-grey-light);
	box-sizing: border-box;
	color: var(--color-grey-dark);
	display: none;
	overflow-x: hidden;
	overflow-y: scroll;
	position: fixed;
	scroll-behavior: smooth;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
}

@media (max-width: 1023px) {
	#mobile-navigation:checked ~ .mobile-navigation {
		display: block;
	}
}

.t-expro .mobile-navigation,
.t-express .mobile-navigation,
.t-xplay .mobile-navigation,
.t-xtrail .mobile-navigation {
	color: white;
}

.t-expro .mobile-navigation {
	background-color: var(--color-blue);
}

.t-express .mobile-navigation {
	background-color: var(--color-red);
}

.t-xplay .mobile-navigation {
	background-color: var(--color-warm-grey);
}

.t-xtrail .mobile-navigation {
	background-color: var(--color-purple);
}

@media (min-width: 1024px) {
	.mobile-navigation {
		display: none;
	}
}

.mobile-navigation__inner {
	min-height: 100vh;
}

.mobile-navigation__header {
	border-top: 1px solid var(--color-faded-black);
	border-bottom: 1px solid var(--color-faded-black);
	box-sizing: border-box;
	display: flex;
	height: 3.25rem;
}

.t-expro .mobile-navigation__header,
.t-express .mobile-navigation__header,
.t-xplay .mobile-navigation__header,
.t-xtrail .mobile-navigation__header {
	border-top: 1px solid var(--color-faded-white);
	border-bottom: 1px solid var(--color-faded-white);
}

.mobile-navigation__header__section {
	box-sizing: border-box;
	flex-basis: 50%;
	flex-grow: 0;
	flex-shrink: 0;
}

.mobile-navigation__header__section:first-child {
	border-right: 1px solid var(--color-faded-black);
}

.t-expro .mobile-navigation__header__section:first-child,
.t-express .mobile-navigation__header__section:first-child,
.t-xplay .mobile-navigation__header__section:first-child,
.t-xtrail .mobile-navigation__header__section:first-child {
	border-right: 1px solid var(--color-faded-white);
}

.mobile-navigation__header__enquire-link {
	color: currentColor;
	display: block;
	font-family: var(--font-title);
	font-size: 0.875rem;
	font-weight: var(--font-weight-bold);
	line-height: 3.25rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	width: 100%;
	height: 100%;
}

.mobile-navigation__header__close-link {
	box-sizing: border-box;
	display: block;
	font-size: 0.75rem;
	font-weight: var(--font-weight-bold);
	line-height: 3.25rem;
	padding-right: 3rem;
	padding-left: 2rem;
	position: relative;
	text-transform: uppercase;
	width: 100%;
	height: 100%;
}

.mobile-navigation__header__close-link::after {
	content: "×";
	font-size: 0.9375rem;
	font-weight: var(--font-weight-normal);
	line-height: 3.125rem;
	position: absolute;
	top: 0;
	right: 2rem;
}

.mobile-navigation__section {
	box-sizing: border-box;
	display: block;
	padding: 2rem 10%;
	width: 100%;
}

@media (min-width: 768px) {
	.mobile-navigation__section {
		padding-right: 12.5%;
		padding-left: 12.5%;
	}
}

.mobile-navigation__section:not(:last-child) {
	border-bottom: 1px solid var(--color-faded-black);
}

.t-expro .mobile-navigation__section:not(:last-child),
.t-express .mobile-navigation__section:not(:last-child),
.t-xplay .mobile-navigation__section:not(:last-child),
.t-xtrail .mobile-navigation__section:not(:last-child) {
	border-bottom: 1px solid var(--color-faded-white);
}

.t-expro .mobile-navigation__section.mobile-navigation__section--expro,
.t-express .mobile-navigation__section.mobile-navigation__section--express,
.t-xplay .mobile-navigation__section.mobile-navigation__section--xplay,
.t-xtrail .mobile-navigation__section.mobile-navigation__section--xtrail {
	display: none;
}

.mobile-navigation__social {
	margin-bottom: 2rem;
}

.mobile-navigation__format {
	color: currentColor;
	display: inline-block;
	font-family: var(--font-title);
	font-size: 1.75rem;
	font-weight: var(--font-weight-bold);
	margin-bottom: 1rem;
	text-decoration: none;
	text-transform: uppercase;
}

.mobile-navigation__label {
	margin-bottom: 0.375rem;
}

.mobile-navigation__format-link {
	color: currentColor;
	font-family: var(--font-title);
	font-weight: var(--font-weight-bold);
	text-decoration: none;
	text-transform: uppercase;
}

.mobile-navigation__checkbox {
	display: none;
}

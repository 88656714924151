.site-footer {
	background-color: black;
	color: white;
	padding-top: 10vw;
	padding-bottom: 10vw;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 0;
}

@media (min-width: 768px) {
	.site-footer {
		font-size: 1rem;
		padding-top: 10vw;
		padding-bottom: 10vw;
	}
}

.sg-section .site-footer {
	position: relative;
}

@media (min-width: 768px) {
	.site-footer__wrapper {
		display: flex;
	}
}

@media (min-width: 768px) {
	.site-footer__primary,
	.site-footer__secondary {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (min-width: 768px) {
	.site-footer__primary-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
}

@media (min-width: 768px) {
	.site-footer__logo-wrapper {
		flex-grow: 1;
	}
}

.site-footer__logo {
	display: block;
	width: 129px;
	height: 24px;
}

@media (max-width: 767px) {
	.site-footer__logo {
		margin-bottom: 2rem;
	}
}

.site-footer__logo .icon {
	display: block;
	fill: white;
	width: 100%;
	height: 100%;
}

.site-footer__social {
	display: none;
}

@media (max-width: 767px) {
	.site-footer__social.site-footer__social--small {
		display: block;
		margin-bottom: 2rem;
	}
}

@media (min-width: 768px) {
	.site-footer__social.site-footer__social--medium {
		display: block;
		margin-bottom: 0.5rem;
	}
}

.site-footer__content {
	margin-bottom: 2rem;
}

@media (min-width: 768px) {
	.site-footer__content {
		margin-bottom: 3rem;
	}
}

@media (min-width: 1024px) {
	.site-footer__content {
		margin-bottom: 4rem;
	}
}

@media (min-width: 1380px) {
	.site-footer__content {
		margin-bottom: 5.25rem;
	}
}

.site-footer__content a {
	font-weight: var(--font-weight-bold);
	text-decoration: none;
}

@media (min-width: 1024px) {
	.site-footer__menu {
		display: flex;
	}
}

.site-footer__navigation {
	display: block;
	font-family: var(--font-title);
	font-weight: var(--font-weight-bold);
	list-style: none;
	margin-top: 0;
	margin-bottom: 2rem;
	padding: 0;
}

.site-footer__navigation:last-child {
	margin-bottom: 0;
}

@media (min-width: 1024px) {
	.site-footer__navigation {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		margin-bottom: 0;
	}
}

.site-footer__navigation__link {
	border-bottom-color: rgb(255 255 255 / 0);
	border-bottom-style: solid;
	border-bottom-width: 1px;
	color: white;
	display: inline-block;
	line-height: 1;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	text-decoration: none;
	transition: border-bottom-color 96ms linear;
}

.site-footer__navigation__link:hover {
	border-bottom-color: rgb(255 255 255 / 0.6);
}

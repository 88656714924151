.video-controls {
	background: linear-gradient(to bottom, rgb(0 0 0 / 0) 0%, rgb(0 0 0 / 0.4) 100%);
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	opacity: 0;
	padding: 0 1.5rem 1.5rem;
	align-items: stretch;
	pointer-events: none;
	position: absolute;
	bottom: 0;
	left: 0;
	transition: opacity 160ms linear;
	width: 100%;
	z-index: 8;
}

@media (min-width: 1024px) {
	.video-controls {
		padding: 0 2rem 2rem;
	}
}

@media (min-width: 1380px) {
	.video-controls {
		padding: 0 3rem 3rem;
	}
}

.video-controls:not(.is-hidden).is-showing,
.video-controls.video-controls--timeline {
	opacity: 1;
	pointer-events: auto;
}

.video-controls.video-controls--timeline {
	background: none;
	display: block;
	padding: 0;
	position: relative;
	bottom: auto;
	left: auto;
}

.video-controls__button,
.video-controls__progress {
	box-sizing: border-box;
	color: currentColor;
	display: flex;
	flex-basis: auto;
	font-size: 1rem;
	align-items: center;
	height: 1.5rem;
}

.video-controls__button:not(:last-child),
.video-controls__progress:not(:last-child) {
	padding-right: 1.5rem;
}

.video-controls__button:hover,
.video-controls__progress:hover {
	cursor: pointer;
}

.video-controls__button {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	flex-grow: 0;
	flex-shrink: 0;
	margin: 0;
	outline: 0;
	text-decoration: none;
}

.video-controls__button.is-toggled {
	color: green;
}

.video-controls__button-label {
	display: block;
	width: 1.5rem;
	height: 1.5rem;
}

.video-controls__icon {
	display: block;
	fill: currentColor;
	width: 100%;
	height: 100%;
}

.video-controls__button:not(.is-toggled) .video-controls__icon.video-controls__icon--toggled {
	display: none;
}

.video-controls__button.is-toggled .video-controls__icon.video-controls__icon--untoggled {
	display: none;
}

.video-controls__progress {
	flex-grow: 1;
	flex-shrink: 1;
	padding-top: 1px;
	position: relative;
}

.video-controls--timeline .video-controls__progress {
	padding-right: 0;
}

.video-controls__progress__range {
	flex-basis: 100%;
	flex-grow: 1;
	position: relative;
	height: 100%;
}

.video-controls__progress__bar,
.video-controls__progress__played {
	pointer-events: none;
	position: absolute;
	left: 0;
	width: 100%;
}

.video-controls__progress__bar {
	background-color: currentColor;
	opacity: 0.3;
	top: calc(50% - 1px);
	height: 2px;
}

@media (min-width: 1024px) {
	.video-controls--light .video-controls__progress__bar {
		background-color: var(--color-faded-black);
	}
}

.video-controls__progress__played {
	background-color: currentColor;
	pointer-events: none;
	position: relative;
	top: calc(50% - 1px);
	transform: scaleX(0);
	transform-origin: 0 0;
	width: 100%;
	height: 2px;
}

@media (min-width: 1024px) {
	.video-controls--light .video-controls__progress__played {
		background-color: black;
	}
}

.video-controls__waypoints {
	counter-reset: waypoints;
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 5;
}

.video-controls__waypoints__item {
	counter-increment: waypoints;
	position: absolute;
	top: 0;
	z-index: 6;
}

.video-controls__waypoints__link {
	appearance: none;
	background: 0;
	border: 0;
	border-bottom-color: currentColor;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	box-shadow: none;
	color: currentColor;
	display: block;
	font-family: var(--font-title);
	font-size: 0.75rem;
	font-weight: var(--font-weight-bold);
	letter-spacing: 1px;
	line-height: 1.5833;
	outline: 0;
	padding: 1.5rem 0 0;
	text-transform: uppercase;
	transform: translateX(-50%);
	transition: border-bottom-color 96ms linear;
	width: auto;
}

@media (min-width: 1024px) {
	.video-controls__waypoints__link {
		font-size: 0.875rem;
		padding-top: 3rem;
	}
}

.video-controls__waypoints__link:hover {
	border-bottom-color: currentColor;
	cursor: pointer;
}

.video-controls__waypoints__link::before {
	background-color: currentColor;
	border-radius: 50%;
	content: "";
	display: block;
	position: absolute;
	top: 0.5rem;
	left: 50%;
	transform: translateX(-50%);
	width: 0.5rem;
	height: 0.5rem;
}

@media (min-width: 768px) and (max-width: 1023px) {
	.video-controls__waypoints__link::before {
		top: 0.5625rem;
	}
}

@media (min-width: 1280px) {
	.video-controls__waypoints__link::before {
		top: 0.5625rem;
	}
}

@media (max-width: 767px) {
	.video-controls__waypoints__link::after {
		content: counter(waypoints);
	}
}

@media (max-width: 767px) {
	.video-controls__waypoints__link > span {
		display: none;
	}
}

@media (min-width: 1024px) {
	.video-controls--light .video-controls__waypoints__link {
		border-bottom-color: var(--color-transparent-black);
		color: black;
	}
}

@media (min-width: 1024px) {
	.video-controls--light .video-controls__waypoints__link:hover {
		border-bottom-color: var(--color-faded-black);
	}
}

@media (min-width: 1024px) {
	.video-controls--light .video-controls__waypoints__link::before {
		background-color: black;
	}
}

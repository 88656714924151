.link {
	color: currentColor;
	display: inline-block;
	font-size: 1rem;
	padding: 0.625rem;
	text-decoration: underline;
}

@media (min-width: 768px) {
	.link {
		font-size: 1.125rem;
	}
}

.link:focus,
.link:hover {
	text-decoration: none;
}

.link:first-child {
	padding-left: 0;
}

.link:last-child {
	padding-right: 0;
}

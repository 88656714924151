.section {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	position: relative;
}

@media (min-width: 768px) {
	.section {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (min-width: 1024px) {
	.section {
		padding-top: 5.5rem;
		padding-bottom: 5.5rem;
	}
}

@media (min-width: 1380px) {
	.section {
		padding-top: 6.875rem;
		padding-bottom: 6.875rem;
	}
}

.section.section--large {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

@media (min-width: 768px) {
	.section.section--large {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
}

@media (min-width: 1024px) {
	.section.section--large {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}
}

@media (min-width: 1380px) {
	.section.section--large {
		padding-top: 10rem;
		padding-bottom: 10rem;
	}
}

.section.section--news-post {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

@media (min-width: 1024px) {
	.section.section--news-post {
		padding-top: 3.125rem;
		padding-bottom: 3.125rem;
	}
}

.section + .section {
	padding-top: 0;
}

.media-slider {
	overflow: hidden;
}

.media-slider__intro {
	margin-bottom: 2rem;
}

@media (min-width: 768px) {
	.media-slider__intro {
		box-sizing: border-box;
		padding-right: 50%;
	}
}

@media (min-width: 1024px) {
	.media-slider__intro {
		margin-bottom: 4.25rem;
	}
}

@media (min-width: 1380px) {
	.media-slider__intro {
		padding-right: 66.6666%;
	}
}

.social-icons {
	font-size: 0;
	list-style: none;
	padding-left: 0;
}

.social-icons__item {
	display: inline-block;
	vertical-align: middle;
}

.social-icons__item + .social-icons__item {
	margin-left: 1rem;
}

.social-icons__link {
	color: currentColor;
	display: block;
	width: 1.25rem;
	height: 1.25rem;
}

.social-icons__link > .icon {
	width: 100%;
	height: 100%;
}

.related-content {
	border-top: 1px solid black;
}

.related-content__header {
	display: flex;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	justify-content: space-between;
	align-items: center;
}

@media (min-width: 1024px) {
	.related-content__header {
		padding-top: 3.125rem;
		padding-bottom: 3.125rem;
	}
}

.related-content__title {
	margin-top: 0;
	margin-bottom: 0;
}

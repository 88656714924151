.site-header {
	border-bottom-color: var(--color-white);
	border-bottom-style: solid;
	border-bottom-width: 1px;
	box-sizing: border-box;
	color: white;
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	transition:
		background-color 96ms linear,
		border-bottom-color 96ms linear,
		color 48ms linear;
	width: 100%;
	z-index: 90;
}

@media (max-width: 1023px) {
	.site-header {
		flex-wrap: wrap;
		transform: translateY(-3.125rem);
		transition:
			background-color 96ms linear,
			border-bottom-color 96ms linear,
			color 48ms linear,
			transform 208ms ease-out;
	}
}

@media (min-width: 1024px) {
	.site-header {
		padding-right: 4rem;
		height: 4rem;
	}
}

.site-header.site-header--hero-dark {
	border-bottom-color: var(--color-white);
	color: var(--color-grey-light);
}

.site-header.site-header--has-no-hero,
.site-header.site-header--is-error,
.site-header.site-header--hero-light {
	border-bottom-color: var(--color-black);
	color: var(--color-black);
}

.t-expro .site-header.site-header--has-no-hero,
.t-expro .site-header.site-header--hero-brand {
	border-bottom-color: var(--color-blue);
	color: var(--color-blue);
}

.t-express .site-header.site-header--has-no-hero,
.t-express .site-header.site-header--hero-brand {
	border-bottom-color: var(--color-red);
	color: var(--color-red);
}

.t-xplay .site-header.site-header--has-no-hero,
.t-xplay .site-header.site-header--hero-brand {
	border-bottom-color: var(--color-warm-grey);
	color: var(--color-warm-grey);
}

.t-xtrail .site-header.site-header--has-no-hero,
.t-xtrail .site-header.site-header--hero-brand {
	border-bottom-color: var(--color-purple);
	color: var(--color-purple);
}

@media (max-width: 1023px) {
	body:not(.is-near-top).is-scrolled-up .site-header {
		transform: translateY(0);
	}
}

.site-header.site-header--is-initially-dark,
body:not(.is-near-top) .site-header,
.is-enquire-page .site-header,
.is-story-page .site-header {
	background-color: white;
	border-bottom-color: var(--color-black);
	color: var(--color-black);
}

body:not(.is-near-top) .t-expro:not(.is-product-detail):not(.is-enquire-page):not(.is-story-page) .site-header,
.t-expro.is-product-detail .site-header,
body.is-near-top .t-expro.is-enquire-page .site-header,
body.is-near-top .t-expro.is-story-page .site-header {
	border-bottom-color: var(--color-blue);
	color: var(--color-blue);
}

body:not(.is-near-top) .t-express:not(.is-product-detail):not(.is-enquire-page):not(.is-story-page) .site-header,
.t-express.is-product-detail .site-header,
body.is-near-top .t-express.is-enquire-page .site-header,
body.is-near-top .t-express.is-story-page .site-header {
	border-bottom-color: var(--color-red);
	color: var(--color-red);
}

body:not(.is-near-top) .t-xplay:not(.is-product-detail):not(.is-enquire-page):not(.is-story-page) .site-header,
.t-xplay.is-product-detail .site-header,
body.is-near-top .t-xplay.is-enquire-page .site-header,
body.is-near-top .t-xplay.is-story-page .site-header {
	border-bottom-color: var(--color-warm-grey);
	color: var(--color-warm-grey);
}

body:not(.is-near-top) .t-xtrail:not(.is-product-detail):not(.is-enquire-page):not(.is-story-page) .site-header,
.t-xtrail.is-product-detail .site-header,
body.is-near-top .t-xtrail.is-enquire-page .site-header,
body.is-near-top .t-xtrail.is-story-page .site-header {
	border-bottom-color: var(--color-purple);
	color: var(--color-purple);
}

body:not(.is-near-top) .t-expro.is-product-detail .site-header,
body:not(.is-near-top) .t-expro.is-enquire-page .site-header,
body:not(.is-near-top) .t-expro.is-story-page .site-header,
body:not(.is-near-top) .t-express.is-product-detail .site-header,
body:not(.is-near-top) .t-express.is-enquire-page .site-header,
body:not(.is-near-top) .t-express.is-story-page .site-header,
body:not(.is-near-top) .t-xplay.is-product-detail .site-header,
body:not(.is-near-top) .t-xplay.is-enquire-page .site-header,
body:not(.is-near-top) .t-xplay.is-story-page .site-header,
body:not(.is-near-top) .t-xtrail.is-product-detail .site-header,
body:not(.is-near-top) .t-xtrail.is-enquire-page .site-header,
body:not(.is-near-top) .t-xtrail.is-story-page .site-header {
	border-bottom-color: var(--color-white);
	color: white;
}

body:not(.is-near-top) .t-expro:not(.is-product-detail):not(.is-enquire-page):not(.is-story-page) .site-header,
body:not(.is-near-top) .t-express:not(.is-product-detail):not(.is-enquire-page):not(.is-story-page) .site-header,
body:not(.is-near-top) .t-xplay:not(.is-product-detail):not(.is-enquire-page):not(.is-story-page) .site-header,
body:not(.is-near-top) .t-xtrail:not(.is-product-detail):not(.is-enquire-page):not(.is-story-page) .site-header,
.has-no-hero .site-header {
	background-color: white;
}

body:not(.is-near-top) .t-expro.is-product-detail .site-header,
body:not(.is-near-top) .t-expro.is-story-page .site-header,
body:not(.is-near-top) .t-expro.is-enquire-page .site-header {
	background-color: var(--color-blue);
}

body:not(.is-near-top) .t-express.is-product-detail .site-header,
body:not(.is-near-top) .t-express.is-story-page .site-header,
body:not(.is-near-top) .t-express.is-enquire-page .site-header {
	background-color: var(--color-red);
}

body:not(.is-near-top) .t-xplay.is-product-detail .site-header,
body:not(.is-near-top) .t-xplay.is-story-page .site-header,
body:not(.is-near-top) .t-xplay.is-enquire-page .site-header {
	background-color: var(--color-warm-grey);
}

body:not(.is-near-top) .t-xtrail.is-product-detail .site-header,
body:not(.is-near-top) .t-xtrail.is-story-page .site-header,
body:not(.is-near-top) .t-xtrail.is-enquire-page .site-header {
	background-color: var(--color-purple);
}

.sg-section .site-header {
	position: relative;
}

@keyframes animate-in-navigation-mobile {
	0% {
		transform: translate(0, -100%) translateZ(0);
	}

	100% {
		transform: translateY(-3.125rem) translateZ(0);
	}
}

@keyframes animate-in-navigation-desktop {
	0% {
		transform: translate(0, -100%) translateZ(0);
	}

	100% {
		transform: translateZ(0);
	}
}

.site-header__close-button-container,
.site-header__enquire-link-container,
.site-header__logo-container,
.site-header__navigation-container {
	box-sizing: border-box;
	flex-grow: 1;
	flex-shrink: 0;
	height: 3.125rem;
}

@media (min-width: 768px) {
	.site-header__close-button-container,
	.site-header__enquire-link-container,
	.site-header__logo-container,
	.site-header__navigation-container {
		height: auto;
	}
}

.site-header__close-button-container,
.site-header__enquire-link-container {
	transition: border-bottom-color 96ms linear;
}

@media (max-width: 1023px) {
	.site-header__close-button-container,
	.site-header__enquire-link-container {
		border-bottom-color: var(--color-white);
		border-bottom-style: solid;
		border-bottom-width: 1px;
	}
}

@media (max-width: 1023px) {
	body:not(.is-near-top) .site-header__close-button-container,
	.is-enquire-page .site-header__close-button-container,
	body:not(.is-near-top) .site-header__enquire-link-container,
	.is-enquire-page .site-header__enquire-link-container {
		border-bottom-color: var(--color-black);
	}
}

@media (max-width: 1023px) {
	body:not(.is-near-top) .t-expro .site-header__close-button-container,
	body.is-near-top .t-expro.is-product-detail .site-header__close-button-container,
	body.is-near-top .t-expro.is-enquire-page .site-header__close-button-container,
	body:not(.is-near-top) .t-expro .site-header__enquire-link-container,
	body.is-near-top .t-expro.is-product-detail .site-header__enquire-link-container,
	body.is-near-top .t-expro.is-enquire-page .site-header__enquire-link-container {
		border-bottom-color: var(--color-blue);
	}
}

@media (max-width: 1023px) {
	body:not(.is-near-top) .t-express .site-header__close-button-container,
	body.is-near-top .t-express.is-product-detail .site-header__close-button-container,
	body.is-near-top .t-express.is-enquire-page .site-header__close-button-container,
	body:not(.is-near-top) .t-express .site-header__enquire-link-container,
	body.is-near-top .t-express.is-product-detail .site-header__enquire-link-container,
	body.is-near-top .t-express.is-enquire-page .site-header__enquire-link-container {
		border-bottom-color: var(--color-red);
	}
}

@media (max-width: 1023px) {
	body:not(.is-near-top) .t-xplay .site-header__close-button-container,
	body.is-near-top .t-xplay.is-product-detail .site-header__close-button-container,
	body.is-near-top .t-xplay.is-enquire-page .site-header__close-button-container,
	body:not(.is-near-top) .t-xplay .site-header__enquire-link-container,
	body.is-near-top .t-xplay.is-product-detail .site-header__enquire-link-container,
	body.is-near-top .t-xplay.is-enquire-page .site-header__enquire-link-container {
		border-bottom-color: var(--color-warm-grey);
	}
}

@media (max-width: 1023px) {
	body:not(.is-near-top) .t-xtrail .site-header__close-button-container,
	body.is-near-top .t-xtrail.is-product-detail .site-header__close-button-container,
	body.is-near-top .t-xtrail.is-enquire-page .site-header__close-button-container,
	body:not(.is-near-top) .t-xtrail .site-header__enquire-link-container,
	body.is-near-top .t-xtrail.is-product-detail .site-header__enquire-link-container,
	body.is-near-top .t-xtrail.is-enquire-page .site-header__enquire-link-container {
		border-bottom-color: var(--color-purple);
	}
}

@media (max-width: 1023px) {
	body:not(.is-near-top) .t-expro.is-product-detail .site-header__close-button-container,
	body:not(.is-near-top) .t-expro.is-enquire-page .site-header__close-button-container,
	body:not(.is-near-top) .t-express.is-product-detail .site-header__close-button-container,
	body:not(.is-near-top) .t-express.is-enquire-page .site-header__close-button-container,
	body:not(.is-near-top) .t-xplay.is-product-detail .site-header__close-button-container,
	body:not(.is-near-top) .t-xplay.is-enquire-page .site-header__close-button-container,
	body:not(.is-near-top) .t-xtrail.is-product-detail .site-header__close-button-container,
	body:not(.is-near-top) .t-xtrail.is-enquire-page .site-header__close-button-container,
	body:not(.is-near-top) .t-expro.is-product-detail .site-header__enquire-link-container,
	body:not(.is-near-top) .t-expro.is-enquire-page .site-header__enquire-link-container,
	body:not(.is-near-top) .t-express.is-product-detail .site-header__enquire-link-container,
	body:not(.is-near-top) .t-express.is-enquire-page .site-header__enquire-link-container,
	body:not(.is-near-top) .t-xplay.is-product-detail .site-header__enquire-link-container,
	body:not(.is-near-top) .t-xplay.is-enquire-page .site-header__enquire-link-container,
	body:not(.is-near-top) .t-xtrail.is-product-detail .site-header__enquire-link-container,
	body:not(.is-near-top) .t-xtrail.is-enquire-page .site-header__enquire-link-container {
		border-bottom-color: var(--color-white);
	}
}

.site-header__close-button-container {
	border-right-color: white;
	border-right-style: solid;
	border-right-width: 1px;
	flex-basis: 3.125rem;
	flex-grow: 0;
	transition: border-right-color 96ms linear;
}

@media (min-width: 768px) {
	.site-header__close-button-container {
		flex-basis: 3.125rem;
	}
}

@media (max-width: 1023px) {
	.site-header__close-button-container {
		order: -1;
	}
}

@media (min-width: 1024px) {
	.site-header__close-button-container {
		flex-basis: 4rem;
	}
}

.site-header--hero-light .site-header__close-button-container {
	border-right-color: black;
}

.t-expro .site-header--hero-brand .site-header__close-button-container {
	border-right-color: var(--color-blue);
}

.t-express .site-header--hero-brand .site-header__close-button-container {
	border-right-color: var(--color-red);
}

.t-xplay .site-header--hero-brand .site-header__close-button-container {
	border-right-color: var(--color-warm-grey);
}

.t-xtrail .site-header--hero-brand .site-header__close-button-container {
	border-right-color: var(--color-purple);
}

body:not(.is-near-top) .site-header__close-button-container,
.is-enquire-page .site-header__close-button-container {
	border-right-color: var(--color-black);
}

body:not(.is-near-top) .t-expro .site-header__close-button-container,
body.is-near-top .t-expro.is-product-detail .site-header__close-button-container,
body.is-near-top .t-expro.is-enquire-pagel .site-header__close-button-container {
	border-right-color: var(--color-blue);
}

body:not(.is-near-top) .t-express .site-header__close-button-container,
body.is-near-top .t-express.is-product-detail .site-header__close-button-container,
body.is-near-top .t-express.is-enquire-page .site-header__close-button-container {
	border-right-color: var(--color-red);
}

body:not(.is-near-top) .t-xplay .site-header__close-button-container,
body.is-near-top .t-xplay.is-product-detail .site-header__close-button-container,
body.is-near-top .t-xplay.is-enquire-page .site-header__close-button-container {
	border-right-color: var(--color-warm-grey);
}

body:not(.is-near-top) .t-xtrail .site-header__close-button-container,
body.is-near-top .t-xtrail.is-product-detail .site-header__close-button-container,
body.is-near-top .t-xtrail.is-enquire-page .site-header__close-button-container {
	border-right-color: var(--color-purple);
}

body:not(.is-near-top) .t-expro.is-product-detail .site-header__close-button-container,
body:not(.is-near-top) .t-expro.is-enquire-page .site-header__close-button-container,
body:not(.is-near-top) .t-express.is-product-detail .site-header__close-button-container,
body:not(.is-near-top) .t-express.is-enquire-page .site-header__close-button-container,
body:not(.is-near-top) .t-xplay.is-product-detail .site-header__close-button-container,
body:not(.is-near-top) .t-xplay.is-enquire-page .site-header__close-button-container,
body:not(.is-near-top) .t-xtrail.is-product-detail .site-header__close-button-container,
body:not(.is-near-top) .t-xtrail.is-enquire-page .site-header__close-button-container {
	border-right-color: var(--color-white);
}

.site-header__close-button {
	color: currentColor;
	display: block;
	font-size: 1.875rem;
	line-height: 3.125rem;
	opacity: 1;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition:
		color 48ms linear,
		opacity 96ms 48ms linear;
	width: 100%;
	height: 100%;
}

@media (min-width: 768px) {
	.site-header__close-button {
		line-height: 3.125rem;
	}
}

@media (min-width: 1024px) {
	.site-header__close-button {
		line-height: 4rem;
	}
}

.site-header__close-button:hover {
	opacity: 1;
	pointer-events: auto;
}

.site-header__enquire-link-container {
	flex-basis: calc(100% - 3.125rem);
	text-align: center;
}

@media (max-width: 767px) {
	.site-header__enquire-link-container {
		padding-right: 3.125rem;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.site-header__enquire-link-container {
		padding-right: 3.125rem;
	}
}

@media (max-width: 1023px) {
	.site-header__enquire-link-container {
		order: 0;
	}
}

@media (min-width: 1024px) {
	.site-header__enquire-link-container {
		flex-basis: auto;
	}
}

.site-header__enquire-link {
	color: currentColor;
	display: inline-block;
	font-family: var(--font-title);
	font-size: 0.875rem;
	font-weight: var(--font-weight-bold);
	line-height: 3.125rem;
	text-decoration: none;
	text-transform: uppercase;
	transition:
		color 48ms linear,
		opacity 96ms 48ms linear;
}

@media (min-width: 768px) {
	.site-header__enquire-link {
		line-height: 3.125rem;
	}
}

@media (min-width: 1024px) {
	.site-header__enquire-link {
		font-size: 0.875rem;
		line-height: 4rem;
		opacity: 1;
		pointer-events: auto;
	}
}

.site-header__logo-container {
	box-sizing: border-box;
	flex-basis: 50%;
}

@media (max-width: 1023px) {
	.site-header__logo-container {
		border-right-color: var(--color-white);
		border-right-style: solid;
		border-right-width: 1px;
		order: 1;
		text-align: center;
		transition: border-right-color 48ms linear;
	}
}

@media (max-width: 1023px) {
	.site-header--hero-light .site-header__logo-container {
		border-right-color: var(--color-black);
	}
}

@media (max-width: 1023px) {
	.t-expro .site-header--hero-brand .site-header__logo-container {
		border-right-color: var(--color-blue);
	}
}

@media (max-width: 1023px) {
	.t-express .site-header--hero-brand .site-header__logo-container {
		border-right-color: var(--color-red);
	}
}

@media (max-width: 1023px) {
	.t-xplay .site-header--hero-brand .site-header__logo-container {
		border-right-color: var(--color-warm-grey);
	}
}

@media (max-width: 1023px) {
	.t-xtrail .site-header--hero-brand .site-header__logo-container {
		border-right-color: var(--color-purple);
	}
}

@media (min-width: 1024px) {
	.site-header__logo-container {
		flex-basis: auto;
		padding-left: 1.5rem;
	}
}

@media (max-width: 1023px) {
	body:not(.is-near-top) .site-header__logo-container {
		border-right-color: var(--color-black);
	}
}

@media (max-width: 1023px) {
	body:not(.is-near-top) .t-expro .site-header__logo-container,
	body.is-near-top .t-expro.is-product-detail .site-header__logo-container,
	body.is-near-top .t-expro.is-enquire-page .site-header__logo-container {
		border-right-color: var(--color-blue);
	}
}

@media (max-width: 1023px) {
	body:not(.is-near-top) .t-express .site-header__logo-container,
	body.is-near-top .t-express.is-product-detail .site-header__logo-container,
	body.is-near-top .t-express.is-enquire-page .site-header__logo-container {
		border-right-color: var(--color-red);
	}
}

@media (max-width: 1023px) {
	body:not(.is-near-top) .t-xplay .site-header__logo-container,
	body.is-near-top .t-xplay.is-product-detail .site-header__logo-container,
	body.is-near-top .t-xplay.is-enquire-page .site-header__logo-container {
		border-right-color: var(--color-warm-grey);
	}
}

@media (max-width: 1023px) {
	body:not(.is-near-top) .t-xtrail .site-header__logo-container,
	body.is-near-top .t-xtrail.is-product-detail .site-header__logo-container,
	body.is-near-top .t-xtrail.is-enquire-page .site-header__logo-container {
		border-right-color: var(--color-purple);
	}
}

@media (max-width: 1023px) {
	body:not(.is-near-top) .t-expro.is-product-detail .site-header__logo-container,
	body:not(.is-near-top) .t-expro.is-enquire-page .site-header__logo-container,
	body:not(.is-near-top) .t-express.is-product-detail .site-header__logo-container,
	body:not(.is-near-top) .t-express.is-enquire-page .site-header__logo-container,
	body:not(.is-near-top) .t-xplay.is-product-detail .site-header__logo-container,
	body:not(.is-near-top) .t-xplay.is-enquire-page .site-header__logo-container,
	body:not(.is-near-top) .t-xtrail.is-product-detail .site-header__logo-container,
	body:not(.is-near-top) .t-xtrail.is-enquire-page .site-header__logo-container {
		border-right-color: var(--color-white);
	}
}

.site-header__logo {
	color: currentColor;
	display: inline-block;
	fill: currentColor;
	font-family: var(--font-title);
	font-size: 1.25rem;
	font-weight: var(--font-weight-bold);
	line-height: 3.125rem;
	text-decoration: none;
	text-transform: uppercase;
	transition:
		color 48ms linear,
		opacity 96ms 48ms linear;
}

@media (min-width: 768px) {
	.site-header__logo {
		line-height: 3.125rem;
	}
}

@media (min-width: 1024px) {
	.site-header__logo {
		line-height: 4rem;
		opacity: 0;
		pointer-events: none;
	}
}

@media (min-width: 1024px) {
	body:not(.is-near-top) .site-header__logo {
		opacity: 1;
		pointer-events: auto;
	}
}

.site-header__logo:hover {
	opacity: 0.75;
}

.site-header__logo .icon-airclad-x {
	margin-top: 1rem;
	width: 5.5rem;
	height: 1rem;
}

@media (min-width: 1024px) {
	.site-header__logo .icon-airclad-x {
		margin-top: 1.5rem;
		width: 7rem;
		height: 1.25rem;
	}
}

.site-header__navigation-container {
	flex-basis: 50%;
	min-width: 0;
}

/* needed otherwise white-space: nowrap breaks */
@media (max-width: 1023px) {
	.site-header__navigation-container {
		order: 2;
	}
}

@media (min-width: 1024px) {
	.site-header__navigation-container {
		flex-basis: auto;
	}
}

.site-header__mobile-navigation-toggle {
	box-sizing: border-box;
	display: block;
	font-size: 0.75rem;
	font-weight: var(--font-weight-bold);
	line-height: 3.125rem;
	padding-right: 2rem;
	padding-left: 1rem;
	position: relative;
	text-transform: uppercase;
	width: 100%;
	height: 100%;
}

@media (min-width: 568px) {
	.site-header__mobile-navigation-toggle {
		padding-right: 3rem;
		padding-left: 2rem;
	}
}

@media (min-width: 1024px) {
	.site-header__mobile-navigation-toggle {
		display: none;
	}
}

.site-header__mobile-navigation-toggle::after {
	content: "+";
	font-size: 0.9375rem;
	font-weight: var(--font-weight-normal);
	line-height: 3.125rem;
	position: absolute;
	top: 0;
	right: 2rem;
}

#mobile-navigation:checked ~ .site-header .site-header__mobile-navigation-toggle::after {
	content: "×";
}

.site-header__mobile-navigation-toggle__label {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 80%;
}

.site-header__navigation {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: flex-end;
}

@media (max-width: 1023px) {
	.site-header__navigation {
		display: none;
	}
}

.site-header__navigation__item {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 4rem;
}

.site-header__navigation__link {
	color: currentColor;
	display: block;
	font-size: 0.75rem;
	font-weight: var(--font-weight-normal);
	line-height: 4rem;
	opacity: 1;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition:
		color 48ms linear,
		opacity 96ms 48ms linear;
	width: 100%;
	height: 4rem;
}

@media (min-width: 1380px) {
	.site-header__navigation__link {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}
}

.site-header__navigation__link:hover,
.site-header__navigation__link.is-active {
	font-weight: var(--font-weight-bold);
	pointer-events: auto;
}

.site-header__navigation__item:first-child .site-header__navigation__link {
	padding-left: 0;
}

.site-header__navigation__item:last-child .site-header__navigation__link {
	padding-right: 0;
}

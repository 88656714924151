.columns {
	overflow-x: hidden;
}

@media (min-width: 768px) {
	.columns {
		display: flex;
	}
}

.columns__item {
	min-height: 87.5vw;
}

@media (max-width: 767px) {
	.columns__item {
		width: 100vw;
	}
}

@media (min-width: 768px) {
	.columns__item {
		flex-basis: 50vw;
		flex-shrink: 0;
		min-height: 43.75vw;
	}
}

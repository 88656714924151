.hero {
	background-color: var(--color-grey);
	color: white;
	display: flex;
	flex-direction: column;
	position: relative;
}

@media (max-width: 1023px) {
	.hero {
		min-height: calc(100vh - 30px);
	}
}

.hero.hero--light {
	color: black;
}

.t-expro .hero.hero--brand {
	color: var(--color-blue);
}

.t-express .hero.hero--brand {
	color: var(--color-red);
}

.t-xplay .hero.hero--brand {
	color: var(--color-warm-grey);
}

.t-xtrail .hero.hero--brand {
	color: var(--color-purple);
}

.hero__background {
	overflow: hidden;
	position: relative;
}

@media (max-width: 1023px) {
	.hero__background {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
		min-height: 56.25vw;
	}
}

@media (min-width: 1024px) {
	.hero__background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.hero__background::after {
	background: linear-gradient(rgb(0 0 0 / 0.2), transparent);
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.hero__background__media {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	min-width: 100%;
	max-width: none;
	height: auto;
	min-height: 100%;
}

@supports (object-fit: cover) {
	.hero__background__media {
		object-fit: cover;
		object-position: 50% 50%;
		width: 100%;
		height: 100%;
	}
}

.hero__content {
	position: relative;
	z-index: 2;
}

@media (max-width: 1023px) {
	.hero__content {
		box-sizing: border-box;
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 1;
	}
}

@media (min-width: 1024px) {
	.hero__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: calc(100vh - 50px);
	}
}

.hero__header__format {
	position: absolute;
	bottom: calc(100% + 10vw);
	left: 10%;
	width: 125px;
	height: 76px;
	z-index: 3;
}

@media (min-width: 768px) {
	.hero__header__format {
		left: 12.5%;
		width: 197px;
		height: 101px;
	}
}

@media (min-width: 1024px) {
	.hero__header__format {
		top: 8rem;
		bottom: auto;
	}
}

@media (min-width: 1680px) {
	.hero__header__format {
		left: calc((100% - 1260px) / 2);
	}
}

.t-airclad-x .hero__header__format {
	width: 150px;
	height: 28px;
}

@media (min-width: 768px) {
	.t-airclad-x .hero__header__format {
		width: 197px;
		height: 36px;
	}
}

@media (min-width: 1380px) {
	.t-airclad-x .hero__header__format {
		width: 276px;
		height: 51px;
	}
}

.hero__header__format .icon {
	display: block;
	fill: white;
	width: 100%;
	height: 100%;
}

.hero--light .hero__header__format .icon {
	fill: black;
}

.t-expro .hero--brand .hero__header__format .icon {
	fill: var(--color-blue);
}

.t-express .hero--brand .hero__header__format .icon {
	fill: var(--color-red);
}

.t-xplay .hero--brand .hero__header__format .icon {
	fill: var(--color-warm-grey);
}

.t-xtrail .hero--brand .hero__header__format .icon {
	fill: var(--color-purple);
}

.hero__header__title,
.hero__timeline {
	color: currentColor;
	padding-right: 10%;
	padding-left: 10%;
}

@media (min-width: 768px) {
	.hero__header__title,
	.hero__timeline {
		padding-right: 12.5%;
		padding-left: 12.5%;
	}
}

@media (min-width: 1680px) {
	.hero__header__title,
	.hero__timeline {
		padding-right: calc((100% - 1260px) / 2);
		padding-left: calc((100% - 1260px) / 2);
	}
}

@media (max-width: 1023px) {
	.t-expro .hero__header__title,
	.t-expro .hero__timeline {
		background-color: var(--color-blue);
		color: white;
	}
}

@media (max-width: 1023px) {
	.t-express .hero__header__title,
	.t-express .hero__timeline {
		background-color: var(--color-red);
		color: white;
	}
}

@media (max-width: 1023px) {
	.t-xplay .hero__header__title,
	.t-xplay .hero__timeline {
		background-color: var(--color-warm-grey);
		color: white;
	}
}

@media (max-width: 1023px) {
	.t-xtrail .hero__header__title,
	.t-xtrail .hero__timeline {
		background-color: var(--color-purple);
		color: white;
	}
}

@media (min-width: 1024px) {
	.hero--light .hero__header__title,
	.hero--light .hero__timeline {
		color: black;
	}
}

@media (min-width: 1024px) {
	.t-expro .hero--brand .hero__header__title,
	.t-expro .hero--brand .hero__timeline {
		color: var(--color-blue);
	}
}

@media (min-width: 1024px) {
	.t-express .hero--brand .hero__header__title,
	.t-express .hero--brand .hero__timeline {
		color: var(--color-red);
	}
}

@media (min-width: 1024px) {
	.t-xplay .hero--brand .hero__header__title,
	.t-xplay .hero--brand .hero__timeline {
		color: var(--color-warm-grey);
	}
}

@media (min-width: 1024px) {
	.t-xtrail .hero--brand .hero__header__title,
	.t-xtrail .hero--brand .hero__timeline {
		color: var(--color-purple);
	}
}

.hero__header__title {
	font-family: var(--font-title);
	font-size: 1.125rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.2222;
	padding-top: 10vw;
	padding-bottom: 10vw;
}

@media (min-width: 1024px) {
	.hero__header__title {
		padding-top: 8.5rem;
		padding-bottom: 10rem;
		padding-left: 50%;
		text-shadow: 0 0 10px rgb(0 0 0 / 0.1);
	}
}

@media (min-width: 1280px) {
	.hero__header__title {
		color: white;
		font-size: 2.25rem;
	}
}

@media (min-width: 1680px) {
	.hero__header__title {
		padding-right: calc((100% - 1260px) / 2);
	}
}

@media (min-width: 1024px) {
	.hero--light .hero__header__title {
		text-shadow: 0 0 10px rgb(255 255 255 / 0.1);
	}
}

.hero__header__title * {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
}

.hero__header__letters {
	box-sizing: border-box;
	padding-top: 4.5rem;
	padding-right: 10%;
	padding-bottom: 10vw;
}

@media (max-width: 1023px) {
	.hero__header__letters {
		position: absolute;
		right: 0;
		bottom: 0;
		min-height: calc(100vh - 30px);
	}
}

@media (min-width: 1024px) {
	.hero__header__letters {
		padding-top: 8.5rem;
		padding-bottom: 10rem;
		padding-left: 50%;
	}
}

@media (min-width: 1680px) {
	.hero__header__letters {
		padding-right: calc((100% - 1260px) / 2);
	}
}

.hero__timeline {
	color: white;
	overflow: hidden;
	padding-bottom: 10vw;
}

@media (min-width: 1024px) {
	.hero__timeline {
		padding-bottom: 8.5rem;
	}
}

.call-to-action {
	background-color: var(--color-grey-light);
	color: black;
	font-size: 0.875rem;
	line-height: 1.75;
}

@media (min-width: 1024px) {
	.call-to-action {
		font-size: 1.25rem;
	}
}

.t-expro .call-to-action {
	background-color: var(--color-blue);
	color: white;
}

.t-express .call-to-action {
	background-color: var(--color-red);
	color: white;
}

.t-xplay .call-to-action {
	background-color: var(--color-warm-grey);
	color: white;
}

.t-xtrail .call-to-action {
	background-color: var(--color-purple);
	color: white;
}

.call-to-action * {
	color: currentColor;
	font-size: inherit;
	line-height: inherit;
}

.call-to-action a {
	font-family: var(--font-title);
	font-weight: var(--font-weight-bold);
	text-decoration: none;
	text-transform: uppercase;
}

.call-to-action a:hover {
	opacity: 1;
}

.call-to-action a:not([class]) {
	border-bottom-color: rgb(0 0 0 / 0);
	border-bottom-style: solid;
	border-bottom-width: 1px;
	transition: border-bottom-color 96ms linear;
}

.t-expro .call-to-action a:not([class]),
.t-express .call-to-action a:not([class]),
.t-xplay .call-to-action a:not([class]),
.t-xtrail .call-to-action a:not([class]) {
	border-bottom-color: rgb(255 255 255 / 0);
}

.call-to-action a:not([class]):hover {
	border-bottom-color: rgb(0 0 0 / 0.6);
	opacity: 1;
}

.t-expro .call-to-action a:not([class]):hover,
.t-express .call-to-action a:not([class]):hover,
.t-xplay .call-to-action a:not([class]):hover,
.t-xtrail .call-to-action a:not([class]):hover {
	border-bottom-color: rgb(255 255 255 / 0.6);
}

.call-to-action p {
	font-weight: var(--font-weight-normal);
}

@media (min-width: 768px) {
	.call-to-action__wrapper {
		display: flex;
	}
}

@media (max-width: 767px) {
	.call-to-action__primary {
		margin-bottom: 5rem;
	}
}

@media (min-width: 768px) {
	.call-to-action__primary {
		box-sizing: border-box;
		flex-basis: 61.5%;
		flex-grow: 0;
		flex-shrink: 0;
		padding-right: 10%;
	}
}

@media (min-width: 1024px) {
	.call-to-action__primary {
		padding-right: 15%;
	}
}

@media (min-width: 768px) {
	.call-to-action__secondary {
		flex-basis: 38.5%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}
